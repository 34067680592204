import React from 'react'
import AdPostForm from '../COMPONENT/adPostForm'
import Header from '../COMPONENT/header'
import SideBar from '../COMPONENT/sideBar'
import MobileNav from '../COMPONENT/mobileNav'
import Footer from '../COMPONENT/footer'
import { Helmet } from 'react-helmet'
export default function AdJobs() {
  return (
    <div>

      <Header/>
      <SideBar/>
      <MobileNav/>

      <section class="banner-part">
         <div class="container">
            <div class="banner-content">
               <h1>Add Jobs</h1> 
            </div>
         </div>
      </section>

      <AdPostForm/>
      <Footer/>

      <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
