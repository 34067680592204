import React,{useState} from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import {useLocation, useNavigate } from 'react-router-dom';
import firecolourBlack from "../images/firecolourLogoBlack.png"
import noProfileAvt from "../images/avatar/No_image_available.svg.webp"
import axios from 'axios';
import Swal from 'sweetalert2';

export default function SideBar() {
   const Token = localStorage.getItem('token')
   const navTo = useNavigate()
   const token = localStorage.getItem('token')
   const [OwnWishlistCount, setOwnWishlistCount] = useState('');

  async function logout(){
      localStorage.clear() 
      navTo('/')
      await Swal.fire("Logout Successfull")
      window.location.reload(false)
      }

   const ID = localStorage.getItem('ID')

   const [profileData, setProfileData] = useState('')

   useEffect(() => {
      
      fetch('https://node.firehrm.com/FK/user/getById/'+ID)
          .then(response => response.json())
          .then(res => {console.log(res)
         setProfileData(res.data && res.data[0])})
  }, []);

  async function getOwnWishlistCount() {
   await axios({
     'method': 'GET',
     'url': `https://node.firehrm.com/FK/user/ownWishListCount`,
     'headers': {
    'Authorization':token,
     'Content-Type': 'application/json',
     },
   }).then((data) => {
     console.log(data)
     setOwnWishlistCount(data?.data?.count)
    
   }).catch((error)=>{
     console.log(error);
   })
   
  }
   useEffect(() => {
     getOwnWishlistCount();
 }, []);


  return (
    <div>
        <aside class="sidebar-part">
         <div class="sidebar-body">
            <div class="sidebar-header"><a href="/" class="sidebar-logo"><img src={firecolourBlack} alt="logo"/></a><button class="sidebar-cross"><i class="fas fa-times"></i></button></div>
            <div class="sidebar-content">
               <div class="sidebar-profile">
               {Token?
               <>
               <a href="" class="sidebar-avatar">{profileData && profileData.profileImage?<img src={profileData && profileData.profileImage} alt="avatar"/>:<img src={noProfileAvt} alt="avatar"/>}</a>
               <h4><a href="" class="sidebar-name">{profileData && profileData.userName}</a></h4>
               </>
               :
               <>
               <a href="/loginForm" class="sidebar-avatar"><img src="images/user.png" alt="avatar"/></a>
               <h4><a href="/loginForm" class="sidebar-name">Login Here</a></h4>
               </>
               }
               {Token? 
               <a href="/adPost" class="btn btn-inline sidebar-post"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>
               :
               <a href="/loginForm" class="btn btn-inline sidebar-post"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>}
               </div>
               <div class="sidebar-menu">
                  <ul class="nav nav-tabs">
                     {/* <li><a href="#main-menu" class="nav-link active" data-toggle="tab">Main Menu</a></li> */}
                     <li><a href="#author-menu" class="nav-link" data-toggle="tab">MENU</a></li>
                  </ul>
                  {/* <div class="tab-pane active" id="main-menu">
                     <ul class="navbar-list">
                        <li class="navbar-item"><a class="navbar-link" href="">Home</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="">About Us</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="">Categories</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="">Advertisement</a></li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>Job Portal</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li class="navbar-item"><a class="navbar-link" href="">Job Page</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="">Job Details</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="">All Categories</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="">Job Alerts</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>Pages</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li><a class="dropdown-link" href="">Ad Compare</a></li>
                              <li><a class="dropdown-link" href="">Ad by Cities</a></li>
                              <li><a class="dropdown-link" href="">Pricing Plan</a></li>
                              <li><a class="dropdown-link" href="">User Form</a></li>
                              <li><a class="dropdown-link" href="">404</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>blogs</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li><a class="dropdown-link" href="">Blog list</a></li>
                              <li><a class="dropdown-link" href="">blog details</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item"><a class="navbar-link" href="">Contact</a></li>
                     </ul>
                  </div> */}
                  <div class="tab-pane" id="author-menu">
                     <ul class="navbar-list">
                        {/* {Token ? 
                        <li class="navbar-item"><a class="navbar-link" href="dashboard.html">Dashboard</a></li>
                        :<li class="navbar-item"><a class="navbar-link" href="loginForm">Dashboard</a></li>} */}
                        {Token ? 
                        <li class="navbar-item"><a class="navbar-link" href="profile">Profile</a></li> 
                        : <li class="navbar-item"><a class="navbar-link" href="loginForm">Profile</a></li>}
                        {Token ? 
                        <li class="navbar-item"><a class="navbar-link" href="adPost">Ad Post</a></li> 
                        : <li class="navbar-item"><a class="navbar-link" href="loginForm">Ad Post</a></li>}
                        {Token ? 
                        <li class="navbar-item"><a class="navbar-link" href="myAds">My Ads</a></li> 
                        :<li class="navbar-item"><a class="navbar-link" href="loginForm">My Ads</a></li>}
                        {/* {Token ? <li class="navbar-item navbar-dropdown"> 
                           <a class="navbar-link" href="#"><span>Job Portal</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li class="navbar-item"><a class="navbar-link" href="add-resume.html">Add Resume</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="manage-resume.html">Manage Resume</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="add-jobs.html">Add Jobs</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="browse-resume.html">Browse Resume</a></li>
                           </ul>
                        </li>
                        :<li class="navbar-item navbar-dropdown"><a class="navbar-link" href="loginForm"><span>Job Portal</span><i class="fas fa-plus"></i></a></li>} */}
                        {Token ? 
                        <li class="navbar-item"><a class="navbar-link" href="profileSetting">Settings</a></li> 
                        : <li class="navbar-item"><a class="navbar-link" href="loginForm">Settings</a></li>}
                        {Token ? 
                        <li class="navbar-item navbar-dropdown"><a class="navbar-link" href="wishListPage"><span>Wish List</span><span>{OwnWishlistCount == 0 ? "" : OwnWishlistCount}</span></a></li>
                        :<li class="navbar-item navbar-dropdown"><a class="navbar-link" href="loginForm"><span>Wish List</span><span></span></a></li>}
                        {/* {Token ? 
                        <li class="navbar-item navbar-dropdown"><a class="navbar-link" href="message.html"><span>Message</span><span>0</span></a></li>
                        :<li class="navbar-item navbar-dropdown"><a class="navbar-link" href="loginForm"><span>Message</span><span>0</span></a></li>}
                        {Token ? 
                        <li class="navbar-item navbar-dropdown"><a class="navbar-link" href="notification.html"><span>Notification</span><span>0</span></a></li>
                        :<li class="navbar-item navbar-dropdown"><a class="navbar-link" href="loginForm"><span>Notification</span><span>0</span></a></li>} */}
                        {Token ? 
                        <li class="navbar-item"><button class="navbar-link" onClick={(e)=>logout()}>Logout</button></li>
                        :<li class="navbar-item"><a class="navbar-link" href="loginForm">Login</a></li>}
                     </ul>
                  </div>
               </div>
               <div class="sidebar-footer">
                  <p>All Rights Reserved By <a href="#">Firekeey</a></p>
               </div>
            </div>
         </div>
      </aside>
      <Helmet>
      <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
      </Helmet>
    </div>
  )
}
