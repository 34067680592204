import React from 'react'

export default function AboutArticle() {
  return (
    <div>
        <section class="about-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-6">
                  <div class="about-content">
                     <h2>Our Motive is to Provide Best for Those Who Deserve</h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit enim minima cumque saepe fuga itaque consectetur aperiam asperiores placeat at modi voluptates atque labore quia, dolore neque dolorum necessitatibus rem aliquid praesentium? Soluta libero rem sunt, quos omnis dolorum reprehenderit.</p>
                  </div>
                  <div class="about-quote">
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse aliquid assumenda tempore voluptatem!</p>
                     <h5>Founder & CEO - <span>Classicads</span></h5>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="row about-image">
                     <div class="col-6 col-lg-6"><img src="images/about/01.jpg" alt="about"/></div>
                     <div class="col-6 col-lg-6"><img src="images/about/02.jpg" alt="about"/></div>
                     <div class="col-6 col-lg-6"><img src="images/about/03.jpg" alt="about"/></div>
                     <div class="col-6 col-lg-6"><img src="images/about/04.jpg" alt="about"/></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
