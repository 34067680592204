import React from "react";
import ReactFacebookLogin from "react-facebook-login";
export default function FacebookLogin(props) {

  let onResponse = resp => {
    console.log(resp);
  };
  return (
    <div style={{marginTop:"50px",marginBottom:"50px",textShadow: "2px 2px"}}>
    <ReactFacebookLogin
    cssClass="m-5 bg-primary card shadow Fantasy"
      appId="701763064260593" // we created this, remember?
      autoLoad={false}
      fields="name,email,picture,contact"
      callback={onResponse}
      onFailure={onResponse}
    />
    </div>
  );
}
