import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function CategoriesSlider() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [categoriesList, setCategoriesList] = useState('');


  async function getCategoriesList() {
    await axios({
      'method': 'GET',
      'url': `https://node.firehrm.com/FK/admin/getAll-category`,
      'headers': {
    //  'Authorization':token,
      'Content-Type': 'application/json',
      },
    }).then((data) => {
      console.log(data.data.data,'categoryconsole')
      setCategoriesList(data.data.data)
     
    }).catch((error)=>{
      console.log(error);
    })
    };
   const nav = useNavigate()
    useEffect(() => {
     getCategoriesList();
  }, []);

  {console.log(categoriesList,'category')}

    function goToCategory(data){
      nav('/adListColumn1',{state:data})
    }
    const state = {
      responsive: {
          0: {
              items: 1,
          },
          450: {
              items: 2,
          },
          600: {
              items: 4,
          },
          1000: {
              items: 5,
          },
      },
  } 

  function goToCategory(e,x){
    e.preventDefault()
    navigate('/adListColumn1',{state:x})
  }

  return (
   
      
    <div>
    {categoriesList.length > 0 ?
    <div>
    <OwlCarousel items={10}
    className="owl-theme"
    loop
    nav
    margin={8}
    center
    autoplay={true}
    dots={false}
    touchDrag={true}
    lazyLoad={true}
    responsive={state.responsive}
    animateOut={'fadeOut'}
    animateIn={'flipInX'}>
  {categoriesList && categoriesList.map((x=>
   // {x.category?.[0] && x.category?.[0].map((y=> 
   <button onClick={(e)=>goToCategory(e,x)} class="suggest-card">
        <section class="suggest-part">
         <div class="container" >
            <div class=" px-5" style={{paddingTop:'100px',paddingRight:'100px',paddingLeft:'100px'}}>
              
                  <img  src={x.categoryImage} />
                  <h6>{x.categoryName}</h6>
               
               </div>
         </div>
      </section>
      </button>
//   ))}
    ))} 
    
</OwlCarousel>
 

         
   </div>
    : "DB Not Connected"}
  
       <Helmet>
       <script src="js/vendor/jquery-1.12.4.min.js"></script>
       <script src="js/vendor/popper.min.js"></script>
       <script src="js/vendor/bootstrap.min.js"></script>
       <script src="js/vendor/slick.min.js"></script>
       <script src="js/custom/slick.js"></script>
       <script src="js/custom/main.js"></script>
       </Helmet>
      
      
    </div>
    
  )
}
