import React,{useState} from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import noProductImg from '../images/product/no-image.jpg'

export default function WishList() {
 const token = localStorage.getItem('token')
 const [OwnWishlist, setOwnWishlist] = useState([]);
 const nav = useNavigate()
   async function getOwnWishlist() {
      await axios({
        'method': 'GET',
        'url': `https://node.firehrm.com/FK/user/view-wishlist`,
        'headers': {
       'Authorization':token,
        'Content-Type': 'application/json',
        },
      }).then((data) => {
        console.log(data,'data')
         setOwnWishlist(data?.data?.count)
       
      }).catch((error)=>{
        console.log(error);
      })
      
     }
      useEffect(() => {
        getOwnWishlist();
    }, []);

    function Navtoproduct(e,x){
      e.preventDefault()
      console.log(x);
      nav('/viewSingleAd',{state:x})
    }
  return (
   <section class="myads-part mt-5">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="header-filter">
                     {/* <div class="filter-show">
                        <label class="filter-label">Show :</label>
                        <select class="custom-select filter-select">
                           <option value="1">12</option>
                           <option value="2">24</option>
                           <option value="3">36</option>
                        </select>
                     </div> */}
                     {/* <div class="filter-short">
                        <label class="filter-label">Short by :</label>
                        <select class="custom-select filter-select">
                           <option selected>all ads</option>
                           <option value="3">booking ads</option>
                           <option value="2">rental ads</option>
                           <option value="1">sale ads</option>
                        </select>
                     </div> */}
                  </div>
               </div>
            </div>
            <div class="row">
        {OwnWishlist && OwnWishlist.map((x =>
                     <button onClick={(e)=>Navtoproduct(e,x?._id)} class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                     <div class="product-card mb-5">
                     <div class="product-media">
                        {x?.productDetails?.productImage?.[0] ? <div class="product-img"><img src={x?.productDetails?.productImage?.[0]} alt="product"/></div> : <div class="product-img"><img src={noProductImg} alt="product"/></div>}
                        {x.subscriptionPlanName == "Standard Plan" ?<div class="cross-vertical-badge product-badge">
                           <i class="fas fa-clipboard-check"></i><span>recommend</span>
                        </div>:""}  
                        {x.subscriptionPlanName == "Premium Plan" ?<div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>:""}
                        <div class="product-type">
                              {x?.productDetails?.adType == 'sale'?<span class="flat-badge sale">sale</span>:''}
                              {x?.productDetails?.adType == 'rent'?<span class="flat-badge rent">rent</span>:''}
                              {x?.productDetails?.adType == 'lease'?<span class="flat-badge booking">Lease</span>:''}
                        </div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><button onClick={(e)=>Navtoproduct(e,x?._id)}>{x?.productDetails?.productCategory}</button></li>
                           <li class="breadcrumb-item active" aria-current="page">{x?.productDetails?.productSubCategory}</li>
                        </ol>
                        <h5 class="product-title"><button onClick={(e)=>Navtoproduct(e,x?._id)}>{x?.productDetails?.productTitle}</button></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>{x?.productDetails?.productLocation},{x?.productOwnerDetails?.state}</span><span><i class="fas fa-clock"></i>{x?.createdAt}</span></div>
                        <div class="product-info">
                           <h5 class="product-price">${x?.productDetails?.productPrice}<span>/{x?.productDetails?.priceCondition}</span></h5>
                           <div class="product-btn"><button onClick={(e)=>Navtoproduct(e,x?._id)} title="Compare" class="fas fa-compress"></button><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
                 
                     </button>
                     ))}
                  </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="footer-pagection">
                     <p class="page-info">Showing 12 of 60 Results</p>
                     <ul class="pagination">
                        <li class="page-item"><a class="page-link" href="#"><i class="fas fa-long-arrow-alt-left"></i></a></li>
                        <li class="page-item"><a class="page-link active" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">...</li>
                        <li class="page-item"><a class="page-link" href="#">67</a></li>
                        <li class="page-item"><a class="page-link" href="#"><i class="fas fa-long-arrow-alt-right"></i></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
  )
}

