import React from "react";
import ReactGoogleLogin from "react-google-login";

export default function GoogleLogin(props) {
  const onResponse = resp => {
    console.log(resp);
  };
  return (
    <ReactGoogleLogin 
      clientId="768988324576-ih1pvn2ks76jt5366nto24tug4iorhhu.apps.googleusercontent.com" // We created this earlier, remember?
      buttonText="Login with Google"
      onSuccess={onResponse}
      onFailure={onResponse}
    />
  );
}