import React from 'react'

export default function AdListCol3Banner() {
  return (
    <div>
    <section class="inner-section single-banner">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="single-content">
                     <h2>ad list</h2>
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">ad-list</li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
