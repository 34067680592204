import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import firecolourWhite from "../images/logofcWhite.png"
import firecolourBlack from "../images/firecolourLogoBlack.png"

export default function ForgetPassword() {
   const [phoneNumber, setPhoneNumber] = useState('')
   const [otp, setOTP] = useState('')
   const [newPassword, setPassword] = useState('')
   const [confirmPassword, setConfirmPassword] = useState('')
   const nav = useNavigate()
   const phN = {phoneNumber}
   const changePasswordData = {phoneNumber,otp,newPassword,confirmPassword}

   function navToHome(){
      nav('/')
    }

    function SendOTP(e){
        e.preventDefault()
        fetch('https://node.firehrm.com/FK/user/forgetPassword',{
            method:'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(phN)
            })
            .then((response)=> response.json())
            
            .then((data) => {
               console.log('data',data)
                console.log('Success:', data.message);
               //  navToHome()
              })
            .catch((error) => {
            console.log('Error:', error);
            });
        }

    function ChangePassword(e){
      e.preventDefault()
      fetch('https://node.firehrm.com/FK/user/forgetPassword',{
          method:'POST',
          headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(changePasswordData)
          })
          .then((response)=> response.json())
          
          .then((data) => {
            //   Notify.show('Toasty!');
              console.log('Success:', data.message);
            //   navToHome()
            })
          .catch((error) => {
          console.log('Error:', error);
          });
      }

  return (
    <div>l
         <section class="user-form-part">
         <div class="user-form-banner">
            <div class="user-form-content">
               <a href="#"><img src={firecolourWhite} alt="logo"/></a>
               <h1>Advertise your assets <span>Buy what are you needs.</span></h1>
               <p>Biggest Online Advertising Marketplace in the World.</p>
            </div>
         </div>
         <div class="user-form-category">
            <div class="user-form-header"><a href="#"><img src={firecolourBlack} alt="logo" width={20} height={40}/></a><a href="/"><i class="fas fa-arrow-left"></i></a></div>
            <div class="user-form-category-btn">
               {/* <ul class="nav nav-tabs">
                  <li><a href="/loginForm" class="nav-link active" 
                  data-toggle="tab"
                  >sign in</a></li>
                  <li><a href="/registerForm" class="nav-link" 
                  data-toggle="tab"
                  >sign up</a></li>
               </ul> */}
            </div>
            
            <div class="tab-pane active" id="login-tab">
               <div class="user-form-title">
                  <h2>Change Password</h2>
                  <p>Use credentials to access your account.</p>
               </div>
               <form>
                  <div class="row">
                     <div class="col-12">
                        <div class="form-group"><input onChange={(e)=>setPhoneNumber(e.target.value)} type="tel" class="form-control" placeholder="Phone number"/><small class="form-alert">Please follow this example - 01XXXXXXXXX</small></div>
                     </div>
                     <div class="col-12">
                        <div class="form-group"><button onClick={(e)=>SendOTP(e)} type="button" class="btn btn-inline"><span>Send OTP</span></button></div>
                     </div>
                     <div class="col-12">
                        <div class="form-group"><input onChange={(e)=>setOTP(e.target.value)} type="tel" class="form-control" id="pass" placeholder="OTP"/><button type="button" class="form-icon"><i class="eye fas fa-eye"></i></button><small class="form-alert">Enter OTP</small></div>
                     </div>
                     <div class="col-12">
                        <div class="form-group"><input onChange={(e)=>setPassword(e.target.value)} type="password" class="form-control" id="pass" placeholder="Password"/><button type="button" class="form-icon"><i class="eye fas fa-eye"></i></button><small class="form-alert">Password must be 6 characters</small></div>
                     </div>
                     <div class="col-12">
                        <div class="form-group"><input onChange={(e)=>setConfirmPassword(e.target.value)} type="password" class="form-control" id="pass" placeholder="Repeat Password"/><button type="button" class="form-icon"><i class="eye fas fa-eye"></i></button><small class="form-alert">Repeat Password must be same as password</small></div>
                     </div>
                     <div class="col-6">
                        {/* <div class="form-group">
                           <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" id="signin-check"/><label class="custom-control-label" for="signin-check">Remember me</label></div>
                        </div> */}
                     </div>
                     <div class="col-6">
                        {/* <div class="form-group text-right"><a href="#" class="form-forgot">Forgot password?</a></div> */}
                     </div>
                     <div class="col-12">
                        <div class="form-group"><button onClick={(e)=>ChangePassword(e)} type="button" class="btn btn-inline"><i class="fas fa-unlock"></i><span>Enter your account</span></button></div>
                     </div>
                  </div>
               </form>
               <div class="user-form-direction">
                  <p>Don't have an account? click on the <span>( sign up )</span>button above.</p>
               </div>
            </div>
         </div>
      </section>
      <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
