import React,{useState} from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import '../App.css';
import firecolourBlack from "../images/firecolourLogoBlack.png"
import firecolourWhite from "../images/logofcWhite.png"
import noProfileAvt from "../images/avatar/No_image_available.svg.webp"

export default function Header() {
   const Token = localStorage.getItem('token')
   const ID = localStorage.getItem('ID')
   const [profileData, setProfileData] = useState('')
   const [input, setInput] = useState('');
   const [adTags, setTags] = useState([]);
   const [isKeyReleased, setIsKeyReleased] = useState(false);

   useEffect(() => {
      
      fetch('https://node.firehrm.com/FK/user/getById/'+ID)
          .then(response => response.json())
          .then(res => {console.log(res)
         setProfileData(res.data && res.data[0])})
  }, []);

  const deleteTag = (index) => {
   setTags(prevState => prevState.filter((tag, i) => i !== index))
 }

 const onChange = (e) => {
   const { value } = e.target;
   setInput(value);
   };
   const onKeyDown = (e) => {
      const { key } = e;
      const trimmedInput = input.trim();
      
      if (key === ',' && trimmedInput.length && !adTags.includes(trimmedInput)) {
         e.preventDefault();
         setTags(prevState => [...prevState, trimmedInput]);
         setInput('');
      }
       
      if (key === "Backspace" && !input.length && adTags.length && isKeyReleased) {
         const tagsCopy = [...adTags];
         const poppedTag = tagsCopy.pop();
         e.preventDefault();
         setTags(tagsCopy);
         setInput(poppedTag);
      }
      
      setIsKeyReleased(false);
      };
      
      const onKeyUp = () => {
      setIsKeyReleased(true);
      }
     
  return (
    <div>
        <header class="header-part">
         <div class="container">
            <div class="header-content">
               <div class="header-left">
                  <button type="button" class="header-widget sidebar-btn">
                   <i class="fas fa-align-left"></i>
                  </button>
                  <a href="/" class="header-logo logo-1-menu">
                     <img src={firecolourBlack} alt="logo"/>
                  </a>
                  <a href="/" class="header-logo logo-2-menu">
                     <img src={firecolourWhite} alt="logo"/>
                  </a>
                  <button type="button" class="header-widget search-btn">
                     <i class="fas fa-search"></i>
                  </button>
               </div>
             
               <div class="header-right">
                  <ul class="header-list">
                  {Token?<a href="/profileSetting" class="header-widget header-user mr-3">
                  {profileData && profileData.profileImage ? <img src={profileData && profileData.profileImage} alt="profile Image"/>:<img src={noProfileAvt} alt="profile Image"/>}<span>{profileData && profileData.userName}</span>
                  </a>:<a href="/loginForm" class="header-widget header-user mr-3">
                     <img src="images/user.png" alt="user"/><span>join me</span>
                  </a>}
                     {/* <li class="header-item"><a href="bookmark.html" class="header-widget"><i class="fas fa-heart"></i><sup>0</sup></a></li> */}
                     {/* <li class="header-item"> */}
                        {/* <button type="button" class="header-widget"><i class="fas fa-envelope"></i><sup>0</sup></button> */}
                        {/* <div class="dropdown-card"> */}
                           {/* <div class="dropdown-header">
                              <h5>message (2)</h5>
                              <a href="message.html">view all</a>
                           </div>
                           <ul class="message-list">
                              <li class="message-item unread">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/01.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>john mahmud <span>now</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                    <span class="message-count">4</span>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/03.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>shipu ahmed <span>3m</span></h6>
                                       <p><span>me:</span>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item unread">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>tahmina bonny <span>2h</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                    <span class="message-count">12</span>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/04.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>nasrullah <span>5d</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img"><img src="images/user.png" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>saikul azam <span>7w</span></h6>
                                       <p><span>me:</span>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>munni akter <span>9m</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/03.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>shahin alam <span>1y</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                     <li class="header-item">
                        <button type="button" class="header-widget"><i class="fas fa-bell"></i><sup>0</sup></button>
                        <div class="dropdown-card">
                           <div class="dropdown-header">
                              <h5>Notification (1)</h5>
                              <a href="notification.html">view all</a>
                           </div>
                           <ul class="notify-list">
                              <li class="notify-item active">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/01.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>john mahmud</span>has added the advertisement post of your <span>booking</span>to his wishlist.</p>
                                       <span class="notify-time">just now</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>tahmina bonny</span>gave you a <span>comment</span>and 5 star <span>review.</span></p>
                                       <span class="notify-time">2 hours ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/03.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>shipu ahmed</span>and <span>4 other</span>have seen your contact number</p>
                                       <span class="notify-time">3 minutes ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>john mahmud</span>has added the advertisement post of your <span>booking</span>to his wishlist.</p>
                                       <span class="notify-time">5 days ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/04.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>labonno khan</span>gave you a <span>comment</span>and 5 star <span>review.</span></p>
                                       <span class="notify-time">4 months ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/01.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>azam khan</span>and <span>4 other</span>have seen your contact number</p>
                                       <span class="notify-time">1 years ago</span>
                                    </div>
                                 </a>
                              </li>
                           </ul>
                        </div> */}
                     {/* </li> */}
                  </ul>
                  {Token ? <a href="AdPost" class="btn btn-inline post-btn"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>:<a href="loginForm" class="btn btn-inline post-btn"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>}
               </div>
            </div>
         </div>
      </header>
      <Helmet>
      <script src="js/vendor/jquery-1.12.4.min.js"></script><script src="js/vendor/popper.min.js"></script><script src="js/vendor/bootstrap.min.js"></script><script src="js/vendor/slick.min.js"></script><script src="js/custom/slick.js"></script><script src="js/custom/main.js"></script>

      </Helmet>
    </div>
  )
}
