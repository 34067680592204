import React,{useState} from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import firecolourWhite from "../images/logofcWhite.png"
import firecolourBlack from "../images/firecolourLogoBlack.png"
import Swal from 'sweetalert2';

export default function RegisterForm() {
   const [phoneNumber, setPhoneNumber] = useState("")
   const [password, setPassword] = useState("")
   const [repeatPassword, setRepeatPassword] = useState("")
   const nav = useNavigate()
   const registerData = {phoneNumber,password,repeatPassword}
   
   
   function navToLogin(){
      nav('/loginForm')
      Swal.fire("Register Successfull","","success")
    }
    function navToRegister(){
      nav('/RegisterForm')
    }

    function UserRegister(e){
      e.preventDefault()
      fetch('https://node.firehrm.com/FK/user/register',{
          method:'POST',
          headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(registerData)
          })
          .then((response)=> response.json())
          
          .then((data) => {
              console.log('Success:', data.message);
              if (data.message == "register successfully"){
                 navToLogin()
              }
              else if(data.message == "your phoneNumber already exist"){
               Swal.fire("Phone Number Already Exist","","info")
                 navToRegister()

              }
              else if(data.message == "phoneNumber must be valid"){
               Swal.fire("Not a valid Phone Number","","warning")
                 navToRegister()   
              }
              else{
               Swal.fire("Error","","error")
                 navToRegister()  
              }
           
          })
          .catch((error) => {
            Swal.fire(error.message,"","error")
          console.log('Error:', error);
          });
      }
  
  return (
    <div>
         <section class="user-form-part">
         <div class="user-form-banner">
            <div class="user-form-content">
               <a href="#"><img src={firecolourWhite} alt="logo"/></a>
               <h1>Advertise your assets <span>Buy what are you needs.</span></h1>
               <p>Biggest Online Advertising Marketplace in the World.</p>
            </div>
         </div>
         <div class="user-form-category">
            <div class="user-form-header"><a href="/"><img src={firecolourBlack} alt="logo" width={20} height={40}/></a><a href="/"><i class="fas fa-arrow-left"></i></a></div>
            <div class="user-form-category-btn">
            <ul class="nav nav-tabs">
                  <li><Link to="/loginForm" class="nav-link " 
                  data-toggle="tab"
                  >sign in</Link></li>
                  <li><Link to="/registerForm" class="nav-link active" 
                  data-toggle="tab"
                  >sign up</Link></li>
               </ul>
            </div>
            <div class="tab-pane active" id="register-tab">
               <div class="user-form-title">
                  <h2>Register</h2> 
                  <p>Setup a new account in a minute.</p>
               </div>
               
               {/* <div class="user-form-devider">
                  <p>or</p>
               </div> */}
               <form>
                  <div class="row">
                     <div class="col-12">
                        <div class="form-group">
                           <input 
                              onChange={(e)=>setPhoneNumber(e.target.value)} 
                              type="tel" 
                              class="form-control" 
                              placeholder="Phone number"
                           />
                           <small 
                              class="form-alert">
                                 Please follow this example - 01XXXXXXXXX
                           </small>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="form-group">
                           <input
                              onChange={(e)=>setPassword(e.target.value)}
                              type="password" 
                              class="form-control" 
                              placeholder="Password"
                           />
                           <button class="form-icon">
                              <i class="eye fas fa-eye"></i>
                           </button>
                           <small class="form-alert">
                              Password must be 5 characters
                           </small>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="form-group">
                           <input 
                              onChange={(e)=>setRepeatPassword(e.target.value)} 
                              type="password" 
                              class="form-control" 
                              placeholder="Repeat Password"
                           />
                           <button class="form-icon">
                              <i class="eye fas fa-eye"></i>
                           </button>
                           <small class="form-alert">
                              Password must be 5 characters
                           </small>
                        </div>
                     </div>
                     <div class="col-12">
                        {/* <div class="form-group">
                           <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" id="signup-check"/><label class="custom-control-label" for="signup-check">I agree to the all <a href="#">terms & consitions</a>of bebostha.</label></div>
                        </div> */}
                     </div>
                     <div class="col-12">
                        <div class="form-group">
                           <button 
                           type="button" 
                           class="btn btn-inline" 
                           onClick={(e)=>UserRegister(e)}>
                              <i class="fas fa-user-check"></i>
                              <span>Create new account</span>
                              </button>
                        </div>
                     </div>
                  </div>
               </form>
               <div class="user-form-direction">
                  <p>Already have an account? click on the <a href='/loginForm'>( sign in )</a>button above.</p>
               </div>
            </div>
         </div>
      </section>
      <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
