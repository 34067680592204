import { Button } from 'bootstrap';
import React,{useState} from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import noProfileAvt from "../images/avatar/No_image_available.svg.webp"
import Swal from 'sweetalert2';

export default function ProfileDashboard() {
   const ID = localStorage.getItem('ID')
   const token = localStorage.getItem('token')
   const nav = useNavigate()
   const [profileData, setProfileData] = useState('')
   const [OwnProductCount, setOwnProductCount] = useState('')
   const [OwnProductReviewCount, setOwnProductReviewCount] = useState('');
   const [OwnWishlistCount, setOwnWishlistCount] = useState('');
   const res = ''
   useEffect(() => {
      
      fetch('https://node.firehrm.com/FK/user/getById/'+ID)
          .then(response => response.json())
          .then(res => {console.log("res",res)
            setProfileData(res.data && res.data[0])})
  }, []);

  async function logout(){
  localStorage.clear()
  await Swal.fire("Logout Successfull")
  nav('/')
  }

async function getOwnProductsCount() {
   await axios({
     'method': 'GET',
     'url': `https://node.firehrm.com/FK/owner/product-count`,
     'headers': {
      'Authorization':token,
     'Content-Type': 'application/json',
     },
   }).then((data) => {
     console.log(data)
     setOwnProductCount(data?.data?.count)
    
   }).catch((error)=>{
     console.log(error);
   })
   
  }
   useEffect(() => {
     getOwnProductsCount();
 }, []);

 async function getOwnProductsReviewCount() {
   await axios({
     'method': 'GET',
     'url': `https://node.firehrm.com/FK/owner/productReview-count`,
     'headers': {
    'Authorization':token,
     'Content-Type': 'application/json',
     },
   }).then((data) => {
     console.log(data)
     setOwnProductReviewCount(data?.data?.count)
    
   }).catch((error)=>{
     console.log(error);
   })
   
  }
   useEffect(() => {
     getOwnProductsReviewCount();
 }, []);

 async function getOwnWishlistCount() {
   await axios({
     'method': 'GET',
     'url': `https://node.firehrm.com/FK/user/ownWishListCount`,
     'headers': {
    'Authorization':token,
     'Content-Type': 'application/json',
     },
   }).then((data) => {
     console.log(data)
     setOwnWishlistCount(data?.data?.count)
    
   }).catch((error)=>{
     console.log(error);
   })
   
  }
   useEffect(() => {
     getOwnWishlistCount();
 }, []);

 



 console.log(OwnProductCount);
  return (
    <div>
        <section class="dash-header-part">
         <div class="container">
            <div class="dash-header-card">
               <div class="row">
                  <div class="col-lg-5">
                     <div class="dash-header-left">
                        <div class="dash-avatar">{profileData && profileData.profileImage?<a href="#"><img src={profileData && profileData.profileImage} alt="profile Image"/></a>:<a href="#"><img src={noProfileAvt} alt="profile Image"/></a>}</div>
                        <div class="dash-intro">
                           <h4><a href="#">{profileData && profileData.userName}</a></h4>
                           <h5>new seller</h5>
                           <ul class="dash-meta">
                              <li><i class="fas fa-phone-alt"></i><span>{profileData && profileData.phoneNumber}</span></li>
                              <li><i class="fas fa-envelope"></i><span>{profileData && profileData.email}</span></li>
                              <li><i class="fas fa-map-marker-alt"></i><span>{profileData && profileData?.city+','+''} {profileData && profileData?.state}</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-7">
                     <div class="dash-header-right">
                        <div class="dash-focus dash-list">
                           <h2>{OwnProductCount}</h2>
                           <p>listing ads</p>
                        </div>
                        <div class="dash-focus dash-book">
                           <h2>{OwnWishlistCount}</h2>
                           <p>total Wishlist</p>
                        </div>
                        <div class="dash-focus dash-rev">
                           <h2>{OwnProductReviewCount}</h2>
                           <p>total review</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12">
                     <div class="dash-header-alert alert fade show">
                        <p>From your account dashboard. you can easily check & view your recent orders, manage your shipping and billing addresses and Edit your password and account details.</p>
                        <button data-dismiss="alert"><i class="fas fa-times"></i></button>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12">
                     <div class="dash-menu-list">
                        <ul>
                           {/* <li><NavLink to="dashboard.html">dashboard</NavLink></li> */}
                           <li><NavLink activeClassName="active" to="/profile">Profile</NavLink></li>
                           <li><NavLink activeClassName="active" to="/adPost">ad post</NavLink></li>
                           <li><NavLink activeClassName="active" to="/myAds">my ads</NavLink></li>
                           <li><NavLink activeClassName="active" to="/profileSetting">settings</NavLink></li>
                           <li><NavLink activeClassName="active" to="/wishListPage">Wish List</NavLink></li>
                           {/* <li><NavLink to="message.html">message</NavLink></li> */}
                           {/* <li><NavLink to="notification.html">notification</NavLink></li> */}
                           <li><button className="ml-5" onClick={(e)=>logout(e)}>LOGOUT</button></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
