import React from 'react'

export default function AboutCounter() {
  return (
    <div>
        <section class="counter-part">
         <div class="container">
            <div class="row">
               <div class="col-md-4 col-lg-4">
                  <div class="counter-card">
                     <div class="counter-image"><img src="images/counter/user.png" alt="user"/></div>
                     <div class="counter-content">
                        <h2><span class="counter-number">6795</span>+</h2>
                        <p>Registered Users</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-4 col-lg-4">
                  <div class="counter-card">
                     <div class="counter-image"><img src="images/counter/ads.png" alt="user"/></div>
                     <div class="counter-content">
                        <h2><span class="counter-number">8977</span>+</h2>
                        <p>community ads</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-4 col-lg-4">
                  <div class="counter-card">
                     <div class="counter-image"><img src="images/counter/review.png" alt="user"/></div>
                     <div class="counter-content">
                        <h2><span class="counter-number">3455</span>+</h2>
                        <p>satisfied feedback</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
