import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import PremiumAds from './premiumAds';
import noProductImg from '../images/product/no-image.jpg'

export default function AdListCol1FilterAndDetails() {
   const nav =useNavigate()
   const x = useLocation()
   console.log(x);
   const [shortBy, setshortBy] = useState("descending");
   const [minPrice, setminprice] = useState('');
   const [maxPrice, setmaxprice] = useState('');
   console.log(shortBy);
   // const {this:props,dataFromParent} = useLocation()
   // const state = {this:props,dataFromParent}
   // console.log({this:props,dataFromParent});
   const [ProductList, setproductList] = useState([]);
   // console.log(state?.state?.categoryName);
   const categoryName = x?.state?.categoryName
   const y = x?.state?.categoryName
   console.log(y);
   const [sale, setsale] = useState();
   const [rent, setrent] = useState();
   const [lease, setlease] = useState()
   const adType = [sale,rent,lease]
   const [City, setcity] = useState('')
   const [Selectcity, setSelectCity] = useState([]);
   const city=[]
   const [selectedCategory,setselectedCategory] = useState('')
   const [Category, setcategory] = useState('');
   const [selectedSubCategory,setselectedSubCategory] = useState('')
   const productSubCategory = [...selectedSubCategory]
   const category = [...selectedCategory]

   async function getallcity() {
      await axios({
        'method': 'GET',
        'url': 'https://node.firehrm.com/FK/owner/getAll-cities',
        'headers': {
       
        'Content-Type': 'application/json',
        },
      }).then((data) => {
        console.log(data.data.data,'city')
        setcity(data?.data?.data)
       
      }).catch((error)=>{
        console.log(error);
      })
      
     }

      useEffect(() => {
         getallcity();
      }, []);

      async function getallcategory() {
         await axios({
           'method': 'GET',
           'url': 'https://node.firehrm.com/FK/admin/getAll-category',
           'headers': {
          
           'Content-Type': 'application/json',
           },
         }).then((data) => {
           console.log(data.data.data,'category')
           setcategory(data?.data?.data)
          
         }).catch((error)=>{
           console.log(error);
         })
         
        }
   
         useEffect(() => {
            getallcategory();
         }, []);
   

   async function getProduct() {
      await axios({
        'method': 'GET',
        'url': 'https://node.firehrm.com/FK/owner/categoryWise-sortMenuFilter/'+y+"/"+shortBy,
        'headers': {
       
        'Content-Type': 'application/json',
        },
      }).then((data) => {
        console.log(data.data.data,'categoryconsole')
        setproductList(data?.data?.data)
       
      }).catch((error)=>{
        console.log(error);
      })
      
     }

      useEffect(() => {
         getProduct();
      }, [shortBy]);


   function PriceSearch(e){
      e.preventDefault()
      fetch('https://node.firehrm.com/FK/owner/categoryWise-priceSearch',{
          method:'POST',
          headers:{
            //   'Authorization':token,
              'Content-Type': 'application/json'
            },
          body: JSON.stringify({categoryName,minPrice,maxPrice})
          })
          .then((response)=> response.json())
          
          .then((data) => {
              console.log('Success price:', data)
               setproductList(data?.data)
          })
          .catch((error) => {
          console.log('Error:', error);
          });
      }

      function AdTypeSearch(e){
         e.preventDefault()
         fetch('https://node.firehrm.com/FK/owner/adType-basedFilter',{
             method:'POST',
             headers:{
               //   'Authorization':token,
                 'Content-Type': 'application/json'
               },
             body: JSON.stringify({categoryName,adType})
             })
             .then((response)=> response.json())
             
             .then((data) => {
                 console.log('Success adtype:', data?.arr)
                  setproductList(data?.arr)
             })
             .catch((error) => {
             console.log('Error:', error);
             });
         }

         function CitySearch(e){
            e.preventDefault()
            fetch('https://node.firehrm.com/FK/owner/city-basedFilter',{
                method:'POST',
                headers:{
                  //   'Authorization':token,
                    'Content-Type': 'application/json'
                  },
                body: JSON.stringify({categoryName,city})
                })
                .then((response)=> response.json())
                
                .then((data) => {
                    console.log('Success city:', data)
                    setproductList(data?.arr)
                })
                .catch((error) => {
                console.log('Error:', error);
                });
            }

            function Categorysearch(e){
               e.preventDefault()
               fetch('https://node.firehrm.com/FK/owner/category-basedFilter',{
                   method:'POST',
                   headers:{
                     //   'Authorization':token,
                       'Content-Type': 'application/json'
                     },
                   body: JSON.stringify({category})
                   })
                   .then((response)=> response.json())
                   
                   .then((data) => {
                       console.log('Success category:', data)
                       setproductList(data?.arr)
                   })
                   .catch((error) => {
                   console.log('Error:', error);
                   });
               }

               function SubCategorysearch(e){
                  e.preventDefault()
                  fetch('https://node.firehrm.com/FK/owner/product-basedFilter',{
                      method:'POST',
                      headers:{
                        //   'Authorization':token,
                          'Content-Type': 'application/json'
                        },
                      body: JSON.stringify({categoryName,productSubCategory})
                      })
                      .then((response)=> response.json())
                      
                      .then((data) => {
                          console.log('Success subcategory:', data)
                          setproductList(data?.arr)
                      })
                      .catch((error) => {
                      console.log('Error:', error);
                      });
                  }


            function Navtoproduct(e,x){
               e.preventDefault()
               console.log(x);
               nav('/viewSingleAd',{state:x})
         }
  return (
   <div>
   <section class="inner-section ad-list-part">
    <div class="container">
       <div class="row content-reverse">
          <div class="col-lg-4 col-xl-3">
             <div class="row">
                <div class="col-md-6 col-lg-12">
                   <div class="product-widget">
                      <h6 class="product-widget-title">Filter by Price</h6>
                      <form class="product-widget-form">
                         <div class="product-widget-group"><input type="tel" onChange={(e)=>setminprice(e.target.value)} placeholder="min - 00"/><input onChange={(e)=>setmaxprice(e.target.value)} type="tel" placeholder="max - 1B"/></div>
                         <button onClick={(e)=>PriceSearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                      </form>
                   </div>
                </div>
                <div class="col-md-6 col-lg-12">
                   <div class="product-widget">
                      <h6 class="product-widget-title">Filter by type</h6>
                      <form class="product-widget-form">
                         <ul class="product-widget-list">
                            <li class="product-widget-item">
                               <div class="product-widget-checkbox"><input type="checkbox" onChange={(e)=>setsale(e.target.value)} value='sale' id="chcek1"/></div>
                               <label class="product-widget-label" for="chcek1"><span class="product-widget-type sale">sales</span><span class="product-widget-number">(15)</span></label>
                            </li>
                            <li class="product-widget-item">
                               <div class="product-widget-checkbox"><input type="checkbox" onChange={(e)=>setrent(e.target.value)} value="rent" id="chcek2"/></div>
                               <label class="product-widget-label" for="chcek2"><span class="product-widget-type rent">rental</span><span class="product-widget-number">(25)</span></label>
                            </li>
                            <li class="product-widget-item">
                               <div class="product-widget-checkbox"><input type="checkbox" onChange={(e)=>setlease(e.target.value)} value="lease" id="chcek3"/></div>
                               <label class="product-widget-label" for="chcek3"><span class="product-widget-type booking">Lease</span><span class="product-widget-number">(35)</span></label>
                            </li>
                         </ul>
                         <button onClick={(e)=>AdTypeSearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                      </form>
                   </div>
                </div>
           
                <div class="col-md-6 col-lg-12">
                   <div class="product-widget">
                      <h6 class="product-widget-title">Filter by cities</h6>
                      <form class="product-widget-form">
                         {/* <div class="product-widget-search"><input type="text" placeholder="Search"/></div> */}
                         <ul class="product-widget-list product-widget-scroll">
                            {City && City.map(x=>
                            <>
                            {x?.city && x?.city.map(y=>
                            <li class="product-widget-item">
                               <div class="product-widget-checkbox">
                                  <input 
                                  onChange={(e)=>setSelectCity([...Selectcity,
                                  y?.cityName])} value={y.cityName} type="checkbox" id="chcek9"/></div>
                               <label class="product-widget-label" for="chcek9"><span class="product-widget-text">{y.cityName}</span><span class="product-widget-number"></span></label>
                            </li>
                            )}
                            </>
                            )}
                         </ul>
                         <button onClick={(e)=>CitySearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                      </form>
                   </div>
                </div>
                <div class="col-md-6 col-lg-12">
                   <div class="product-widget">
                      <h6 class="product-widget-title">Filter by Category</h6>
                      <form class="product-widget-form">
                         {/* <div class="product-widget-search"><input type="text" placeholder="Search"/></div> */}
                         <ul class="product-widget-list product-widget-scroll">
                            {Category && Category.map(x=>
                         
                            <li class="product-widget-item">
                               <div class="product-widget-checkbox">
                                  <input 
                                  onChange={(e)=>setselectedCategory([...selectedCategory,
                                  x?.categoryName])} value={x?.categoryName} type="checkbox" id="chcek9"/></div>
                               <label class="product-widget-label" for="chcek9"><span class="product-widget-text">{x?.categoryName}</span><span class="product-widget-number"></span></label>
                            </li>
                            )}
                         </ul>
                         <button onClick={(e)=>Categorysearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                      </form>
                   </div>
                </div>
                <div class="col-md-6 col-lg-12">
                   <div class="product-widget">
                      <h6 class="product-widget-title">Filter by Sub-Category</h6>
                      <form class="product-widget-form">
                         {/* <div class="product-widget-search"><input type="text" placeholder="Search"/></div> */}
                         <ul class="product-widget-list product-widget-scroll">
                            {Category && Category.map(x=>
                             <>
                             {x?.subCategoryName && x?.subCategoryName.map((y=>
                             <li class="product-widget-item">
                               <div class="product-widget-checkbox">
                                  <input 
                                  onChange={(e)=>setselectedSubCategory([...selectedSubCategory,
                                  y])} value={y} type="checkbox" id="chcek9"/></div>
                               <label class="product-widget-label" for="chcek9"><span class="product-widget-text">{y}</span><span class="product-widget-number"></span></label>
                            </li>
                            ))}
                            </>

                            )}
                         </ul>
                         <button onClick={(e)=>SubCategorysearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                      </form>
                   </div>
                </div>
                
             </div> 
          </div>
          <div class="col-lg-8 col-xl-9">
             <div class="row">
                <div class="col-lg-12">
                   <div class="header-filter">
                      <div class="filter-show">
                         <label class="filter-label">Show :</label>
                         <select class="custom-select filter-select">
                            <option value="1">12</option>
                            <option value="2">24</option>
                            <option value="3">36</option>
                         </select>
                      </div>
                      <div class="filter-short">

                   
                         <label class="filter-label">Sort by :</label>
                         <select onChange={(e)=>setshortBy(e.target.value)} class="custom-select filter-select">
                            {/* <option defaultValue>default</option> */}
                            <option value="descending">High to low Price</option>
                            <option value="ascending">Low to high Price</option>
                            <option value="mostViewed">Most Viewed</option>
                            <option defaultValue ="new">New Product</option>
                         </select>
                      </div>
                      {/* <div class="filter-action"><a href="ad-list-column3.html" title="Three Column" class="active"><i class="fas fa-th"></i></a><a href="ad-list-column2.html" title="Two Column"><i class="fas fa-th-large"></i></a><a href="ad-list-column1.html" title="One Column"><i class="fas fa-th-list"></i></a></div> */}
                   </div>
                </div>
             </div>
            
             <div class="row">
   {ProductList && ProductList.map((x =>
                <button onClick={(e)=>Navtoproduct(e,x?._id)} class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="product-card mb-5">
                <div class="product-media">
                   <div class="product-img">{x?.productDetails?.productImage?.[0]?<img src={x?.productDetails?.productImage?.[0]} width={250} height={178} alt="product"/>:<img src={noProductImg} width={250} height={178} alt="product"/>}</div>
                   {x.subscriptionPlanName == "Standard Plan" ?<div class="cross-vertical-badge product-badge">
                      <i class="fas fa-clipboard-check"></i><span>recommend</span>
                   </div>:""}  
                   {x.subscriptionPlanName == "Premium Plan" ?<div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>:""}
                   <div class="product-type">
                         {x?.productDetails?.adType == 'sale'?<span class="flat-badge sale">sale</span>:''}
                         {x?.productDetails?.adType == 'rent'?<span class="flat-badge rent">rent</span>:''}
                         {x?.productDetails?.adType == 'lease'?<span class="flat-badge booking">Lease</span>:''}
                   </div>
                   <ul class="product-action">
                      <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                      <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                      <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                   </ul>
                </div>
                <div class="product-content">
                   <ol class="breadcrumb product-category">
                      <li><i class="fas fa-tags"></i></li>
                      <li class="breadcrumb-item"><button onClick={(e)=>Navtoproduct(e,x?._id)}>{x?.productDetails?.productCategory}</button></li>
                      <li class="breadcrumb-item active" aria-current="page">{x?.productDetails?.productSubCategory}</li>
                   </ol>
                   <h5 class="product-title"><button onClick={(e)=>Navtoproduct(e,x?._id)}>{x?.productDetails?.productTitle}</button></h5>
                   <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>{x?.productDetails?.productLocation},{x?.productOwnerDetails?.state}</span><br/><span><i class="fas fa-clock"></i>{x?.createdAt}</span></div>
                   <div class="product-info">
                      <h5 class="product-price">${x?.productDetails?.productPrice}<span>/{x?.productDetails?.priceCondition}</span></h5>
                      <div class="product-btn"><button 
                     //  onClick={(e)=>Navtoproduct(e,x?._id)}
                       title="Compare" class="fas fa-compress"></button><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                   </div>
                </div>
             </div>
            
                </button>
                ))}
            
           
             </div>
             <div class="row">
                <div class="col-lg-12">
                   <div class="footer-pagection">
                      <p class="page-info">Showing 12 of 60 Results</p>
                      <ul class="pagination">
                         <li class="page-item"><a class="page-link" href="#"><i class="fas fa-long-arrow-alt-left"></i></a></li>
                         <li class="page-item"><a class="page-link active" href="#">1</a></li>
                         <li class="page-item"><a class="page-link" href="#">2</a></li>
                         <li class="page-item"><a class="page-link" href="#">3</a></li>
                         <li class="page-item">...</li>
                         <li class="page-item"><a class="page-link" href="#">67</a></li>
                         <li class="page-item"><a class="page-link" href="#"><i class="fas fa-long-arrow-alt-right"></i></a></li>
                      </ul>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </section>
</div>
  )
 }
