import React,{useState,useEffect} from 'react'
import ProfileDashboard from '../COMPONENT/profileDashboard'
import ProfileUpdate from '../COMPONENT/profileUpdate'
import Header from '../COMPONENT/header'
import SideBar from '../COMPONENT/sideBar'
import MobileNav from '../COMPONENT/mobileNav'

export default function ProfileSetting() {
    const ID = localStorage.getItem('ID')
    const [profileSettings, setProfileSettings] = useState()
    const resp = ''
    
    useEffect(() => {
       // GET request using fetch inside useEffect React hook
       fetch('https://node.firehrm.com/FK/user/getById/'+ID)
           .then(response => response.json())
           .then(resp => {console.log('resp',resp);
           setProfileSettings(resp.data && resp.data[0])
           
          })                  
   // empty dependency array means this effect will only run once (like componentDidMount in classes)
   }, []);

   
  return (
    <div>
      <Header/>
        <SideBar/>
        <MobileNav/>
        <section class="single-banner dashboard-banner">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="single-content">
                     <h2>setting</h2>
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">setting</li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <ProfileDashboard/>
      <ProfileUpdate/>
    </div>
  )
}
