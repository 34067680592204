import React from 'react'
import Header from '../COMPONENT/header'
import SideBar from '../COMPONENT/sideBar'
import MobileNav from '../COMPONENT/mobileNav'
import Footer from '../COMPONENT/footer'
import { Helmet } from 'react-helmet'

export default function Error404() {
  return (
    <div>
      
      <Header/>
      <SideBar/>
      <MobileNav/>
      <section class="error-part">
         <div class="container">
            <div class="error">
               <h1>404</h1>
               <h2>Oops! Something Went Wrong?</h2>
               <a href="index.html" class="btn btn-outline"><i class="fas fa-home"></i><span>go to homepage</span></a>
            </div>
         </div>
      </section>
      <Footer/>
      <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
