import React from 'react';
import CategoriesSlider from '../COMPONENT/categoriesSlider';
import FeatureAds from '../COMPONENT/premiumAds';
import HomeBanner from '../COMPONENT/homeBanner';
import IntroPart from '../COMPONENT/introPart';
import NicheAds from '../COMPONENT/nicheAds';
import RecomendAds from '../COMPONENT/recomendAds';
import SubscriptionPlan from '../COMPONENT/subscription';
import TopCategories from '../COMPONENT/topCategories';
import TopCities from '../COMPONENT/topCities';
import TrendAds from '../COMPONENT/trendAds';
import Blog from '../COMPONENT/blog';
import Header from '../COMPONENT/header'
import SideBar from '../COMPONENT/sideBar'
import MobileNav from '../COMPONENT/mobileNav'
import Footer from '../COMPONENT/footer';
import { Helmet } from 'react-helmet';
export default function Home() {
   
  return (
    <div>

      <Header/>
      <SideBar/>
      <MobileNav/>
      <HomeBanner/>
      <CategoriesSlider/>
      <FeatureAds/>
      <RecomendAds/>
      <TrendAds/>
      {/* <NicheAds/> */}
      {/* <TopCities/> */}
      {/* <TopCategories/> */}
      {/* <IntroPart/> */}
      {/* <SubscriptionPlan/> */}
      {/* <Blog/> */}
   
      {/* <header class="header-part">
         <div class="container">
            <div class="header-content">
               <div class="header-left">
                  <button type="button" class="header-widget sidebar-btn">
                   <i class="fas fa-align-left"></i>
                  </button>
                  <a href="index.html" class="header-logo logo-1-menu">
                     <img src="images/logo.png" alt="logo"/>
                  </a>
                  <a href="index.html" class="header-logo logo-2-menu">
                     <img src="images/logo-1.png" alt="logo"/>
                  </a>
                  <a href="user-form.html" class="header-widget header-user">
                     <img src="images/user.png" alt="user"/><span>join me</span>
                  </a>
                  <button type="button" class="header-widget search-btn">
                     <i class="fas fa-search"></i>
                  </button>
               </div>
               <form class="header-form">
                  <div class="header-search"><button type="submit" title="Search Submit "><i class="fas fa-search"></i></button><input type="text" placeholder="Search, Whatever you needs..."/><button type="button" title="Search Option" class="option-btn"><i class="fas fa-sliders-h"></i></button></div>
                  <div class="header-option">
                     <div class="option-grid">
                        <div class="option-group"><input type="text" placeholder="City"/></div>
                        <div class="option-group"><input type="text" placeholder="State"/></div>
                        <div class="option-group"><input type="text" placeholder="Min Price"/></div>
                        <div class="option-group"><input type="text" placeholder="Max Price"/></div>
                        <button type="submit"><i class="fas fa-search"></i><span>Search</span></button>
                     </div>
                  </div>
               </form>
               <div class="header-right">
                  <ul class="header-list">
                     <li class="header-item"><a href="bookmark.html" class="header-widget"><i class="fas fa-heart"></i><sup>0</sup></a></li>
                     <li class="header-item">
                        <button type="button" class="header-widget"><i class="fas fa-envelope"></i><sup>0</sup></button>
                        <div class="dropdown-card">
                           <div class="dropdown-header">
                              <h5>message (2)</h5>
                              <a href="message.html">view all</a>
                           </div>
                           <ul class="message-list">
                              <li class="message-item unread">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/01.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>john mahmud <span>now</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                    <span class="message-count">4</span>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/03.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>shipu ahmed <span>3m</span></h6>
                                       <p><span>me:</span>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item unread">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>tahmina bonny <span>2h</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                    <span class="message-count">12</span>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/04.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>nasrullah <span>5d</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img"><img src="images/user.png" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>saikul azam <span>7w</span></h6>
                                       <p><span>me:</span>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>munni akter <span>9m</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                              <li class="message-item">
                                 <a href="message.html" class="message-link">
                                    <div class="message-img active"><img src="images/avatar/03.jpg" alt="avatar"/></div>
                                    <div class="message-text">
                                       <h6>shahin alam <span>1y</span></h6>
                                       <p>How are you my best frien...</p>
                                    </div>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                     <li class="header-item">
                        <button type="button" class="header-widget"><i class="fas fa-bell"></i><sup>0</sup></button>
                        <div class="dropdown-card">
                           <div class="dropdown-header">
                              <h5>Notification (1)</h5>
                              <a href="notification.html">view all</a>
                           </div>
                           <ul class="notify-list">
                              <li class="notify-item active">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/01.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>john mahmud</span>has added the advertisement post of your <span>booking</span>to his wishlist.</p>
                                       <span class="notify-time">just now</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>tahmina bonny</span>gave you a <span>comment</span>and 5 star <span>review.</span></p>
                                       <span class="notify-time">2 hours ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/03.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>shipu ahmed</span>and <span>4 other</span>have seen your contact number</p>
                                       <span class="notify-time">3 minutes ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/02.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>john mahmud</span>has added the advertisement post of your <span>booking</span>to his wishlist.</p>
                                       <span class="notify-time">5 days ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/04.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>labonno khan</span>gave you a <span>comment</span>and 5 star <span>review.</span></p>
                                       <span class="notify-time">4 months ago</span>
                                    </div>
                                 </a>
                              </li>
                              <li class="notify-item">
                                 <a href="#" class="notify-link">
                                    <div class="notify-img"><img src="images/avatar/01.jpg" alt="avatar"/></div>
                                    <div class="notify-content">
                                       <p class="notify-text"><span>azam khan</span>and <span>4 other</span>have seen your contact number</p>
                                       <span class="notify-time">1 years ago</span>
                                    </div>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </li>
                  </ul>
                  <a href="ad-post.html" class="btn btn-inline post-btn"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>
               </div>
            </div>
         </div>
      </header>
      <aside class="sidebar-part">
         <div class="sidebar-body">
            <div class="sidebar-header"><a href="index.html" class="sidebar-logo"><img src="images/logo.png" alt="logo"/></a><button class="sidebar-cross"><i class="fas fa-times"></i></button></div>
            <div class="sidebar-content">
               <div class="sidebar-profile">
                  <a href="#" class="sidebar-avatar"><img src="images/avatar/01.jpg" alt="avatar"/></a>
                  <h4><a href="#" class="sidebar-name">Jackon Honson</a></h4>
                  <a href="ad-post.html" class="btn btn-inline sidebar-post"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>
               </div>
               <div class="sidebar-menu">
                  <ul class="nav nav-tabs">
                     <li><a href="#main-menu" class="nav-link active" data-toggle="tab">Main Menu</a></li>
                     <li><a href="#author-menu" class="nav-link" data-toggle="tab">Author Menu</a></li>
                  </ul>
                  <div class="tab-pane active" id="main-menu">
                     <ul class="navbar-list">
                        <li class="navbar-item"><a class="navbar-link" href="index.html">Home</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="about.html">About Us</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="category-list.html">Categories</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="ad-list-column2.html">Advertisement</a></li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>Job Portal</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li class="navbar-item"><a class="navbar-link" href="job-page.html">Job Page</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="job-details.html">Job Details</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="job-categories.html">All Categories</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="job-alerts.html">Job Alerts</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>Pages</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li><a class="dropdown-link" href="compare.html">Ad Compare</a></li>
                              <li><a class="dropdown-link" href="cities.html">Ad by Cities</a></li>
                              <li><a class="dropdown-link" href="price.html">Pricing Plan</a></li>
                              <li><a class="dropdown-link" href="user-form.html">User Form</a></li>
                              <li><a class="dropdown-link" href="404.html">404</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>blogs</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li><a class="dropdown-link" href="blog-list.html">Blog list</a></li>
                              <li><a class="dropdown-link" href="blog-details.html">blog details</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item"><a class="navbar-link" href="contact.html">Contact</a></li>
                     </ul>
                  </div>
                  <div class="tab-pane" id="author-menu">
                     <ul class="navbar-list">
                        <li class="navbar-item"><a class="navbar-link" href="dashboard.html">Dashboard</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="profile.html">Profile</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="ad-post.html">Ad Post</a></li>
                        <li class="navbar-item"><a class="navbar-link" href="my-ads.html">My Ads</a></li>
                        <li class="navbar-item navbar-dropdown">
                           <a class="navbar-link" href="#"><span>Job Portal</span><i class="fas fa-plus"></i></a>
                           <ul class="dropdown-list">
                              <li class="navbar-item"><a class="navbar-link" href="add-resume.html">Add Resume</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="manage-resume.html">Manage Resume</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="add-jobs.html">Add Jobs</a></li>
                              <li class="navbar-item"><a class="navbar-link" href="browse-resume.html">Browse Resume</a></li>
                           </ul>
                        </li>
                        <li class="navbar-item"><a class="navbar-link" href="setting.html">Settings</a></li>
                        <li class="navbar-item navbar-dropdown"><a class="navbar-link" href="bookmark.html"><span>bookmark</span><span>0</span></a></li>
                        <li class="navbar-item navbar-dropdown"><a class="navbar-link" href="message.html"><span>Message</span><span>0</span></a></li>
                        <li class="navbar-item navbar-dropdown"><a class="navbar-link" href="notification.html"><span>Notification</span><span>0</span></a></li>
                        <li class="navbar-item"><a class="navbar-link" href="user-form.html">Logout</a></li>
                     </ul>
                  </div>
               </div>
               <div class="sidebar-footer">
                  <p>All Rights Reserved By <a href="#">Firekeey</a></p>
               </div>
            </div>
         </div>
      </aside>
      <nav class="mobile-nav">
         <div class="container">
            <div class="mobile-group"><a href="index.html" class="mobile-widget"><i class="fas fa-home"></i><span>home</span></a><a href="user-form.html" class="mobile-widget"><i class="fas fa-user"></i><span>join me</span></a><a href="ad-post.html" class="mobile-widget plus-btn"><i class="fas fa-plus"></i><span>Ad Post</span></a><a href="notification.html" class="mobile-widget"><i class="fas fa-bell"></i><span>notify</span><sup>0</sup></a><a href="message.html" class="mobile-widget"><i class="fas fa-envelope"></i><span>message</span><sup>0</sup></a></div>
         </div>
      </nav>
      <section class="banner-part">
         <div class="container">
            <div class="banner-content">
               <h1>You can #Buy, #Rent, #Booking anything from here.</h1>
               <p>Buy and sell everything from used cars to mobile phones and computers, or search for property, jobs and more in the world.</p>
               <a href="ad-list-column3.html" class="btn btn-outline"><i class="fas fa-eye"></i><span>Show all ads</span></a>
            </div>
         </div>
      </section>
      <section class="suggest-part">
         <div class="container">
            <div class="suggest-slider slider-arrow">
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/automobile.png" alt="car"/>
                  <h6>automobile</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/furniture.png" alt="furniture"/>
                  <h6>furniture</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/properties.png" alt="house"/>
                  <h6>properties</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/fashion.png" alt="food"/>
                  <h6>fashion</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/electronics.png" alt="cycle"/>
                  <h6>electronics</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/hospitality.png" alt="clothes"/>
                  <h6>hospitality</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/gadgets.png" alt="computer"/>
                  <h6>gadgets</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/education.png" alt="phone"/>
                  <h6>education</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/software.png" alt="scooter"/>
                  <h6>software</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/food.png" alt="television"/>
                  <h6>food</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/services.png" alt="truck"/>
                  <h6>services</h6>
                  <p>(4,521) ads</p>
               </a>
               <a href="ad-list-column3.html" class="suggest-card">
                  <img src="images/suggest/animals.png" alt="pet"/>
                  <h6>animals</h6>
                  <p>(4,521) ads</p>
               </a>
            </div>
         </div>
      </section>
      <section class="section feature-part">
         <div class="container">
            <div class="row">
               <div class="col-md-5 col-lg-5">
                  <div class="section-side-heading">
                     <h2>Find your needs in our best <span>Featured Ads</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur fugiat eaque alias nobis doloremque culpa nam.</p>
                     <a href="ad-list-column3.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all featured</span></a>
                  </div>
               </div>
               <div class="col-md-7 col-lg-7">
                  <div class="feature-card-slider slider-arrow">
                     <div class="feature-card">
                        <a href="#" class="feature-img"><img src="images/product/10.jpg" alt="feature"/></a>
                        <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                        <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                        <div class="feature-content">
                           <ol class="breadcrumb feature-category">
                              <li><span class="flat-badge rent">rent</span></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">private car</li>
                           </ol>
                           <h3 class="feature-title"><a href="ad-details-left.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                           <div class="feature-meta"><span class="feature-price">$1200<small>/Monthly</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                        </div>
                     </div>
                     <div class="feature-card">
                        <a href="#" class="feature-img"><img src="images/product/01.jpg" alt="feature"/></a>
                        <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                        <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                        <div class="feature-content">
                           <ol class="breadcrumb feature-category">
                              <li><span class="flat-badge booking">booking</span></li>
                              <li class="breadcrumb-item"><a href="#">Property</a></li>
                              <li class="breadcrumb-item active" aria-current="page">House</li>
                           </ol>
                           <h3 class="feature-title"><a href="ad-details-left.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                           <div class="feature-meta"><span class="feature-price">$800<small>/perday</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                        </div>
                     </div>
                     <div class="feature-card">
                        <a href="#" class="feature-img"><img src="images/product/08.jpg" alt="feature"/></a>
                        <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                        <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                        <div class="feature-content">
                           <ol class="breadcrumb feature-category">
                              <li><span class="flat-badge sale">sale</span></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">iphone</li>
                           </ol>
                           <h3 class="feature-title"><a href="ad-details-left.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                           <div class="feature-meta"><span class="feature-price">$1150<small>/Negotiable</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                        </div>
                     </div>
                     <div class="feature-card">
                        <a href="#" class="feature-img"><img src="images/product/06.jpg" alt="feature"/></a>
                        <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                        <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                        <div class="feature-content">
                           <ol class="breadcrumb feature-category">
                              <li><span class="flat-badge sale">sale</span></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">cycle</li>
                           </ol>
                           <h3 class="feature-title"><a href="ad-details-left.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                           <div class="feature-meta"><span class="feature-price">$455<small>/fixed</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                        </div>
                     </div>
                  </div>
                  <div class="feature-thumb-slider">
                     <div class="feature-thumb"><img src="images/product/10.jpg" alt="feature"/></div>
                     <div class="feature-thumb"><img src="images/product/01.jpg" alt="feature"/></div>
                     <div class="feature-thumb"><img src="images/product/08.jpg" alt="feature"/></div>
                     <div class="feature-thumb"><img src="images/product/06.jpg" alt="feature"/></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="section recomend-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Our Recommend <span>Ads</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="recomend-slider slider-arrow">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/01.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-clipboard-check"></i><span>recommend</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Duplex House</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1500<span>/Per Day</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/03.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-clipboard-check"></i><span>recommend</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">stationary</a></li>
                              <li class="breadcrumb-item active" aria-current="page">books</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$470<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/10.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-clipboard-check"></i><span>recommend</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">private car</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$3300<span>/per month</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/09.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-clipboard-check"></i><span>recommend</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">animals</a></li>
                              <li class="breadcrumb-item active" aria-current="page">cat</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$900<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/02.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-clipboard-check"></i><span>recommend</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">fashion</a></li>
                              <li class="breadcrumb-item active" aria-current="page">shoes</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$384<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="center-50"><a href="ad-list-column3.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all recommend</span></a></div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="section trend-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Popular Trending <span>Ads</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center">
               <div class="col-md-11 col-lg-8 col-xl-6">
                  <div class="product-card standard">
                     <div class="product-media">
                        <div class="product-img"><img src="images/product/01.jpg" alt="product"/></div>
                        <div class="cross-vertical-badge product-badge"><i class="fas fa-bolt"></i><span>trending</span></div>
                        <div class="product-type"><span class="flat-badge booking">booking</span></div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><a href="#">property</a></li>
                           <li class="breadcrumb-item active" aria-current="page">house</li>
                        </ol>
                        <h5 class="product-title"><a href="ad-details-right.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                        <div class="product-info">
                           <h5 class="product-price">$974<span>/per day</span></h5>
                           <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-11 col-lg-8 col-xl-6">
                  <div class="product-card standard">
                     <div class="product-media">
                        <div class="product-img"><img src="images/product/02.jpg" alt="product"/></div>
                        <div class="cross-vertical-badge product-badge"><i class="fas fa-bolt"></i><span>trending</span></div>
                        <div class="product-type"><span class="flat-badge sale">sale</span></div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><a href="#">fashion</a></li>
                           <li class="breadcrumb-item active" aria-current="page">shoes</li>
                        </ol>
                        <h5 class="product-title"><a href="ad-details-right.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                        <div class="product-info">
                           <h5 class="product-price">$384<span>/fixed</span></h5>
                           <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-11 col-lg-8 col-xl-6">
                  <div class="product-card standard">
                     <div class="product-media">
                        <div class="product-img"><img src="images/product/03.jpg" alt="product"/></div>
                        <div class="cross-vertical-badge product-badge"><i class="fas fa-bolt"></i><span>trending</span></div>
                        <div class="product-type"><span class="flat-badge sale">sale</span></div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><a href="#">stationary</a></li>
                           <li class="breadcrumb-item active" aria-current="page">book</li>
                        </ol>
                        <h5 class="product-title"><a href="ad-details-right.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                        <div class="product-info">
                           <h5 class="product-price">$78<span>/Negotiable</span></h5>
                           <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-11 col-lg-8 col-xl-6">
                  <div class="product-card standard">
                     <div class="product-media">
                        <div class="product-img"><img src="images/product/04.jpg" alt="product"/></div>
                        <div class="cross-vertical-badge product-badge"><i class="fas fa-bolt"></i><span>trending</span></div>
                        <div class="product-type"><span class="flat-badge sale">sale</span></div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><a href="#">electronics</a></li>
                           <li class="breadcrumb-item active" aria-current="page">television</li>
                        </ol>
                        <h5 class="product-title"><a href="ad-details-right.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                        <div class="product-info">
                           <h5 class="product-price">$756<span>/fixed</span></h5>
                           <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-11 col-lg-8 col-xl-6">
                  <div class="product-card standard">
                     <div class="product-media">
                        <div class="product-img"><img src="images/product/05.jpg" alt="product"/></div>
                        <div class="cross-vertical-badge product-badge"><i class="fas fa-bolt"></i><span>trending</span></div>
                        <div class="product-type"><span class="flat-badge sale">sale</span></div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><a href="#">gadget</a></li>
                           <li class="breadcrumb-item active" aria-current="page">headphone</li>
                        </ol>
                        <h5 class="product-title"><a href="ad-details-right.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                        <div class="product-info">
                           <h5 class="product-price">$245<span>/Negotiable</span></h5>
                           <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-11 col-lg-8 col-xl-6">
                  <div class="product-card standard">
                     <div class="product-media">
                        <div class="product-img"><img src="images/product/06.jpg" alt="product"/></div>
                        <div class="cross-vertical-badge product-badge"><i class="fas fa-bolt"></i><span>trending</span></div>
                        <div class="product-type"><span class="flat-badge rent">rent</span></div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </div>
                     <div class="product-content">
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><a href="#">automobile</a></li>
                           <li class="breadcrumb-item active" aria-current="page">cycle</li>
                        </ol>
                        <h5 class="product-title"><a href="ad-details-right.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                        <div class="product-info">
                           <h5 class="product-price">$75<span>/per hour</span></h5>
                           <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="center-20"><a href="ad-list-column3.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all trend</span></a></div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="section niche-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Browse Our Top <span>Niche</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="niche-nav">
                     <ul class="nav nav-tabs">
                        <li><a href="#ratings" class="nav-link active" data-toggle="tab">top ratings</a></li>
                        <li><a href="#advertiser" class="nav-link" data-toggle="tab">top advertiser</a></li>
                        <li><a href="#engaged" class="nav-link" data-toggle="tab">top engaged</a></li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="tab-pane active" id="ratings">
               <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/07.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">resort</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1590<span>/per week</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/08.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">mobile</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$454<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/09.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">animal</a></li>
                              <li class="breadcrumb-item active" aria-current="page">cat</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$235<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/10.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">private car</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$768<span>/per month</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/11.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Duplex house</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1470<span>/per day</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/13.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">electronics</a></li>
                              <li class="breadcrumb-item active" aria-current="page">laptop</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1550<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/14.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">bike</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$90<span>/per hour</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/15.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">camera</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1200<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane " id="advertiser">
               <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/08.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">mobile</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$454<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/07.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">resort</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1590<span>/per week</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/10.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">private car</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$768<span>/per month</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/09.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">animal</a></li>
                              <li class="breadcrumb-item active" aria-current="page">cat</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$235<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/13.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">electronics</a></li>
                              <li class="breadcrumb-item active" aria-current="page">laptop</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1550<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/11.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Duplex house</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1470<span>/per day</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/15.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">camera</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1200<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/14.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">bike</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$90<span>/per hour</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="engaged">
               <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/15.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">camera</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1200<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/07.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">resort</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1590<span>/per week</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/09.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">animal</a></li>
                              <li class="breadcrumb-item active" aria-current="page">cat</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$235<span>/Negotiable</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/10.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">private car</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$768<span>/per month</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/08.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">gadget</a></li>
                              <li class="breadcrumb-item active" aria-current="page">mobile</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$454<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/13.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge sale">sale</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">electronics</a></li>
                              <li class="breadcrumb-item active" aria-current="page">laptop</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1550<span>/fixed</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/14.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge rent">rent</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">automobile</a></li>
                              <li class="breadcrumb-item active" aria-current="page">bike</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$90<span>/per hour</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                     <div class="product-card">
                        <div class="product-media">
                           <div class="product-img"><img src="images/product/11.jpg" alt="product"/></div>
                           <div class="cross-vertical-badge product-badge"><i class="fas fa-fire"></i><span>top niche</span></div>
                           <div class="product-type"><span class="flat-badge booking">booking</span></div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content">
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a href="#">Luxury</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Duplex house</li>
                           </ol>
                           <h5 class="product-title"><a href="ad-details-left.html">Lorem ipsum dolor sit amet consect adipisicing elit</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>Uttara, Dhaka</span><span><i class="fas fa-clock"></i>30 min ago</span></div>
                           <div class="product-info">
                              <h5 class="product-price">$1470<span>/per day</span></h5>
                              <div class="product-btn"><a href="compare.html" title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="center-20"><a href="ad-list-column3.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all ads</span></a></div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="section city-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Top Cities by <span>Ads</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-6 col-md-6 col-lg-3">
                  <a href="ad-list-column3.html" class="city-card" style={{background: "url(images/cities/01.jpg) no-repeat center; background-size: cover"}}>
                     <div class="city-content">
                        <h4>Los Angeles</h4>
                        <p>(25) ads</p>
                     </div>
                  </a>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4">
                  <a href="ad-list-column3.html" class="city-card" style={{background: "url(images/cities/02.jpg) no-repeat center; background-size: cover"}}>
                     <div class="city-content">
                        <h4>san francisco</h4>
                        <p>(25) ads</p>
                     </div>
                  </a>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-5">
                  <a href="ad-list-column3.html" class="city-card" style={{background: "url(images/cities/03.jpg) no-repeat center; background-size: cover"}}>
                     <div class="city-content">
                        <h4>california</h4>
                        <p>(25) ads</p>
                     </div>
                  </a>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-5">
                  <a href="ad-list-column3.html" class="city-card" style={{background: "url(images/cities/04.jpg) no-repeat center; background-size: cover"}}>
                     <div class="city-content">
                        <h4>new york</h4>
                        <p>(25) ads</p>
                     </div>
                  </a>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4">
                  <a href="ad-list-column3.html" class="city-card" style={{background: "url(images/cities/05.jpg) no-repeat center; background-size: cover"}}>
                     <div class="city-content">
                        <h4>manhattan</h4>
                        <p>(25) ads</p>
                     </div>
                  </a>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-3">
                  <a href="ad-list-column3.html" class="city-card" style={{background: "url(images/cities/06.jpg) no-repeat center; background-size: cover"}}>
                     <div class="city-content">
                        <h4>Baltimore</h4>
                        <p>(25) ads</p>
                     </div>
                  </a>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="center-20"><a href="cities.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all Cities</span></a></div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="section category-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Top Categories by <span>Ads</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/electronics.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>electronics</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>television</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>Generetor</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>Washing machine</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>air condition</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>iron</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/gadgets.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>gadgets</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>computer</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>mobile</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>camera</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>dron</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>airphone</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/furnitures.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>furnitures</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>dyning table</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>chair</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>sofa</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>tea table</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>dressing table</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/animals.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>animals</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>dyning table</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>chair</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>sofa</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>tea table</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>dressing table</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/fashions.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>fashions</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>jeans</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>underware</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>shirt</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>jacket</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>shorts</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/motorbikes.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>motorbikes</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>dyning table</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>chair</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>sofa</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>tea table</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>dressing table</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/properties.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>properties</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>dyning table</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>chair</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>sofa</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>tea table</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>dressing table</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div class="category-card">
                     <div class="category-head">
                        <img src="images/category/automobiles.jpg" alt="category"/>
                        <a href="category-details.html" class="category-content">
                           <h4>automobiles</h4>
                           <p>(3678)</p>
                        </a>
                     </div>
                     <ul class="category-list">
                        <li>
                           <a href="#">
                              <h6>dyning table</h6>
                              <p>(34)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>chair</h6>
                              <p>(24)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>sofa</h6>
                              <p>(12)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>tea table</h6>
                              <p>(19)</p>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <h6>dressing table</h6>
                              <p>(56)</p>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="center-20"><a href="category-list.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all categories</span></a></div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="intro-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Do you have something to advertise?</h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                     <a href="ad-post.html" class="btn btn-outline"><i class="fas fa-plus-circle"></i><span>post your ad</span></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      <section class="price-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Best Reliable Pricing Plans</h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-6 col-lg-4">
                  <div class="price-card">
                     <div class="price-head">
                        <i class="flaticon-bicycle"></i>
                        <h3>$00</h3>
                        <h4>Free Plan</h4>
                     </div>
                     <ul class="price-list">
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>1 Regular Ad for 7 days</p>
                        </li>
                        <li>
                           <i class="fas fa-times"></i>
                           <p>No Credit card required</p>
                        </li>
                        <li>
                           <i class="fas fa-times"></i>
                           <p>No Top or Featured Ad</p>
                        </li>
                        <li>
                           <i class="fas fa-times"></i>
                           <p>No Ad will be bumped up</p>
                        </li>
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>Limited Support</p>
                        </li>
                     </ul>
                     <div class="price-btn"><a href="user-form.html" class="btn btn-inline"><i class="fas fa-sign-in-alt"></i><span>Register Now</span></a></div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4">
                  <div class="price-card price-active">
                     <div class="price-head">
                        <i class="flaticon-car-wash"></i>
                        <h3>$23</h3>
                        <h4>Standard Plan</h4>
                     </div>
                     <ul class="price-list">
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>1 Recom Ad for 30 days</p>
                        </li>
                        <li>
                           <i class="fas fa-times"></i>
                           <p>No Featured Ad Available</p>
                        </li>
                        <li>
                           <i class="fas fa-times"></i>
                           <p>No Ad will be bumped up</p>
                        </li>
                        <li>
                           <i class="fas fa-times"></i>
                           <p>No Top Ad Available</p>
                        </li>
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>Basic Support</p>
                        </li>
                     </ul>
                     <div class="price-btn"><a href="user-form.html" class="btn btn-inline"><i class="fas fa-sign-in-alt"></i><span>Register Now</span></a></div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4">
                  <div class="price-card">
                     <div class="price-head">
                        <i class="flaticon-airplane"></i>
                        <h3>$49</h3>
                        <h4>Premium Plan</h4>
                     </div>
                     <ul class="price-list">
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>1 Featured Ad for 60 days</p>
                        </li>
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>Access to All features</p>
                        </li>
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>With Recommended</p>
                        </li>
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>Ad Top Category</p>
                        </li>
                        <li>
                           <i class="fas fa-plus"></i>
                           <p>Priority Support</p>
                        </li>
                     </ul>
                     <div class="price-btn"><a href="user-form.html" class="btn btn-inline"><i class="fas fa-sign-in-alt"></i><span>Register Now</span></a></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      //
      <section class="blog-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Read Our <span>Recent Articles</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="blog-slider slider-arrow">
                     <div class="blog-card">
                        <div class="blog-img">
                           <img src="images/blog/01.jpg" alt="blog"/>
                           <div class="blog-overlay"><span class="marketing">Marketing</span></div>
                        </div>
                        <div class="blog-content">
                           <a href="#" class="blog-avatar"><img src="images/avatar/01.jpg" alt="avatar"/></a>
                           <ul class="blog-meta">
                              <li>
                                 <i class="fas fa-user"></i>
                                 <p><a href="#">johnMahmud</a></p>
                              </li>
                              <li>
                                 <i class="fas fa-clock"></i>
                                 <p>02 Feb 2021</p>
                              </li>
                           </ul>
                           <div class="blog-text">
                              <h4><a href="blog-details.html">Lorem ipsum dolor sit amet eius minus elit cum quaerat volupt.</a></h4>
                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus veniam ad dolore labore laborum perspiciatis...</p>
                           </div>
                           <a href="blog-details.html" class="blog-read"><span>read more</span><i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                     </div>
                     <div class="blog-card">
                        <div class="blog-img">
                           <img src="images/blog/02.jpg" alt="blog"/>
                           <div class="blog-overlay"><span class="advertise">advertise</span></div>
                        </div>
                        <div class="blog-content">
                           <a href="#" class="blog-avatar"><img src="images/avatar/02.jpg" alt="avatar"/></a>
                           <ul class="blog-meta">
                              <li>
                                 <i class="fas fa-user"></i>
                                 <p><a href="#">LabonnoKhan</a></p>
                              </li>
                              <li>
                                 <i class="fas fa-clock"></i>
                                 <p>02 Feb 2021</p>
                              </li>
                           </ul>
                           <div class="blog-text">
                              <h4><a href="blog-details.html">Lorem ipsum dolor sit amet eius minus elit cum quaerat volupt.</a></h4>
                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus veniam ad dolore labore laborum perspiciatis...</p>
                           </div>
                           <a href="blog-details.html" class="blog-read"><span>read more</span><i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                     </div>
                     <div class="blog-card">
                        <div class="blog-img">
                           <img src="images/blog/03.jpg" alt="blog"/>
                           <div class="blog-overlay"><span class="safety">safety</span></div>
                        </div>
                        <div class="blog-content">
                           <a href="#" class="blog-avatar"><img src="images/avatar/03.jpg" alt="avatar"/></a>
                           <ul class="blog-meta">
                              <li>
                                 <i class="fas fa-user"></i>
                                 <p><a href="#">johnMahmud</a></p>
                              </li>
                              <li>
                                 <i class="fas fa-clock"></i>
                                 <p>02 Feb 2021</p>
                              </li>
                           </ul>
                           <div class="blog-text">
                              <h4><a href="blog-details.html">Lorem ipsum dolor sit amet eius minus elit cum quaerat volupt.</a></h4>
                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus veniam ad dolore labore laborum perspiciatis...</p>
                           </div>
                           <a href="blog-details.html" class="blog-read"><span>read more</span><i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                     </div>
                     <div class="blog-card">
                        <div class="blog-img">
                           <img src="images/blog/04.jpg" alt="blog"/>
                           <div class="blog-overlay"><span class="security">security</span></div>
                        </div>
                        <div class="blog-content">
                           <a href="#" class="blog-avatar"><img src="images/avatar/04.jpg" alt="avatar"/></a>
                           <ul class="blog-meta">
                              <li>
                                 <i class="fas fa-user"></i>
                                 <p><a href="#">TahminaBonny</a></p>
                              </li>
                              <li>
                                 <i class="fas fa-clock"></i>
                                 <p>02 Feb 2021</p>
                              </li>
                           </ul>
                           <div class="blog-text">
                              <h4><a href="blog-details.html">Lorem ipsum dolor sit amet eius minus elit cum quaerat volupt.</a></h4>
                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus veniam ad dolore labore laborum perspiciatis...</p>
                           </div>
                           <a href="blog-details.html" class="blog-read"><span>read more</span><i class="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="blog-btn"><a href="blog-list.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all blogs</span></a></div>
               </div>
            </div>
         </div>
      </section>
      //
      <footer class="footer-part">
         <div class="container">
            <div class="row newsletter">
               <div class="col-lg-6">
                  <div class="news-content">
                     <h2>Subscribe for Latest Offers</h2>
                     <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam, aliquid reiciendis! Exercitationem soluta provident non.</p>
                  </div>
               </div>
               <div class="col-lg-6">
                  <form class="news-form"><input type="text" placeholder="Enter Your Email Address"/><button class="btn btn-inline"><i class="fas fa-envelope"></i><span>Subscribe</span></button></form>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-6 col-md-6 col-lg-3">
                  <div class="footer-content">
                     <h3>Contact Us</h3>
                     <ul class="footer-address">
                        <li>
                           <i class="fas fa-map-marker-alt"></i>
                           <p>No-61, Varsabari Complex, 2nd Floor Ponmeni,Main Road, Bypass Rd, Madurai, 625016, Tamil Nadu, India</p>
                        </li>
                        <li>
                           <i class="fas fa-envelope"></i>
                           <p>support@firekeey.com <span>info@firekeey.com</span></p>
                        </li>
                        <li>
                           <i class="fas fa-phone-alt"></i>
                           <p>+8801838288389 <span>+8801941101915</span></p>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-3">
                  <div class="footer-content">
                     <h3>Quick Links</h3>
                     <ul class="footer-widget">
                        <li><a href="#">Store Location</a></li>
                        <li><a href="#">Orders Tracking</a></li>
                        <li><a href="#">My Account</a></li>
                        <li><a href="#">Size Guide</a></li>
                        <li><a href="#">Faq</a></li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-3">
                  <div class="footer-content">
                     <h3>Information</h3>
                     <ul class="footer-widget">
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Delivery System</a></li>
                        <li><a href="#">Secure Payment</a></li>
                        <li><a href="#">Contact Us</a></li>
                        <li><a href="#">Sitemap</a></li>
                     </ul>
                  </div>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-3">
                  <div class="footer-info">
                     <a href="#"><img src="images/logo-1.png" alt="logo"/></a>
                     <ul class="footer-count">
                        <li>
                           <h5>929,238</h5>
                           <p>Registered Users</p>
                        </li>
                        <li>
                           <h5>242,789</h5>
                           <p>Community Ads</p>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-12">
                  <div class="footer-card-content">
                     <div class="footer-payment"><a href="#"><img src="images/pay-card/01.jpg" alt="01"/></a><a href="#"><img src="images/pay-card/02.jpg" alt="02"/></a><a href="#"><img src="images/pay-card/03.jpg" alt="03"/></a><a href="#"><img src="images/pay-card/04.jpg" alt="04"/></a></div>
                     <div class="footer-option"><button type="button" data-toggle="modal" data-target="#language"><i class="fas fa-globe"></i>English</button><button type="button" data-toggle="modal" data-target="#currency"><i class="fas fa-dollar-sign"></i>USD</button></div>
                     <div class="footer-app"><a href="#"><img src="images/play-store.png" alt="play-store"/></a><a href="#"><img src="images/app-store.png" alt="app-store"/></a></div>
                  </div>
               </div>
            </div>
         </div>
         <div class="footer-end">
            <div class="container">
               <div class="footer-end-content">
                  <p>All Copyrights Reserved &copy; Developed by <a href="#">FireKeey</a></p>
                  <ul class="footer-social">
                     <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                     <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                     <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                     <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                     <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                     <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                     <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                     <li><a href="#"><i class="fab fa-dribbble"></i></a></li>
                  </ul>
               </div>
            </div>
         </div>
      </footer>
      <div class="modal fade" id="currency">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h4>Choose a Currency</h4>
                  <button class="fas fa-times" data-dismiss="modal"></button>
               </div>
               <div class="modal-body"><button class="modal-link">Indian Rupee (INR) - ₹</button><button class="modal-link active">United States Doller (USD) - $</button><button class="modal-link">Euro (EUR) - €</button><button class="modal-link">British Pound (GBP) - £</button><button class="modal-link">Australian Dollar (AUD) - A$</button><button class="modal-link">Canadian Dollar (CAD) - C$</button></div>
            </div>
         </div>
      </div>
      <div class="modal fade" id="language">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h4>Choose a Language</h4>
                  <button class="fas fa-times" data-dismiss="modal"></button>
               </div>
               <div class="modal-body"><button class="modal-link active">English</button><button class="modal-link">bangali</button><button class="modal-link">arabic</button><button class="modal-link">germany</button><button class="modal-link">spanish</button></div>
            </div>
         </div>
   </div> */}
   
   <Footer/>
   <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
