import React from 'react'
import AdPostForm from '../COMPONENT/adPostForm'
import Header from '../COMPONENT/header'
import SideBar from '../COMPONENT/sideBar'
import MobileNav from '../COMPONENT/mobileNav'
import Footer from '../COMPONENT/footer'
import { Helmet } from 'react-helmet'
import ProfileDashboard from '../COMPONENT/profileDashboard'
import ChoosePlan from '../COMPONENT/choosePlan'

export default function ChoosePackageforPostAd() {
  return (
    <div>
            <div>

<Header/>
<SideBar/>
<MobileNav/>

<section class="single-banner dashboard-banner">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="single-content">
               <h2>ad post</h2>
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">ad-post</li>
               </ol>
            </div>
         </div>
      </div>
   </div>
</section>

<ProfileDashboard/>
<ChoosePlan/>
<Footer/>

<Helmet>
<script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
<script type="media_type" src="../js/vendor/popper.min.js"></script>
<script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
<script type="media_type"src="../js/custom/main.js"></script>
<script type="media_type"src="../js/vendor/slick.min.js"></script>
<script type="media_type"src="../js/custom/slick.js"></script>
</Helmet>
</div>
    </div>
  )
}
