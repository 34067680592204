import React from 'react'
import Relatedad from '../COMPONENT/relatedad'
import ViewSingleAdDetails from '../COMPONENT/viewSingleAdDetails'
import Header from '../COMPONENT/header'
import SideBar from '../COMPONENT/sideBar'
import MobileNav from '../COMPONENT/mobileNav'
import Footer from '../COMPONENT/footer'
import { Helmet } from 'react-helmet'
import RecomendAds from '../COMPONENT/recomendAds'
export default function ViewSingleAd(e,x) {
  return (
    <div>

      <Header/>
      <SideBar/>
      <MobileNav/>

      <section class="single-banner">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="single-content">
                     <h2>ad details grid</h2>
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item"><a href="ad-list-column3.html">ad-column3</a></li>
                        <li class="breadcrumb-item active" aria-current="page">ad-details-grid</li>
                     </ol>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <ViewSingleAdDetails {...x}/>
      {/* <RecomendAds/> */}
      <Footer/>
      
      <Helmet>
     <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
     <script type="media_type" src="../js/vendor/popper.min.js"></script>
     <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
     <script type="media_type"src="../js/custom/main.js"></script>
     <script type="media_type"src="../js/vendor/slick.min.js"></script>
     <script type="media_type"src="../js/custom/slick.js"></script>
     </Helmet>        
     </div>
  )}
