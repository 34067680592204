import React from 'react'

export default function HomeBanner() {
  return (
    <div>
        <section class="banner-part">
         <div class="container">
            <div class="banner-content">
               <h1>You can #Buy, #Rent, #Booking anything from here.</h1>
               <p>Buy and sell everything from used cars to mobile phones and computers, or search for property, jobs and more in the world.</p>
               <a href="adListColumn3" class="btn btn-outline"><i class="fas fa-eye"></i><span>Show all ads</span></a>
            </div>
         </div>
      </section>
    </div>
  )
}
