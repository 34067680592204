import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import FeatureAds from './premiumAds'
import Swal from 'sweetalert2';

export default function AdPostForm() {
   
   const token =localStorage.getItem('token')
   const [productTitle, setProductTitle] = useState('')
   const [productImage, setproductImage] = useState([])
   const [productImage1, setProductImage1] = useState([])
   const [productCategory, setProductCategory] = useState('')
   const [productSubCategory,setSubProductCategory] = useState('');
   const [productPrice, setProductPrice] = useState('')
   const [productLocation, setProductLocation] = useState('')
   const [priceCondition, setPriceCondition] = useState('')
   const [adType, setAdType] = useState('')
   const [rentOrLeasePrice, setRentOrLease] = useState('')
   const [productCondition, setProductCondition] = useState('')
   const [adDescription, setAdDescription] = useState('')
   // const [adTags, setAdTag] = useState('')
   const [input, setInput] = useState('');
   const [adTags, setTags] = useState([]);
   // console.log(tags);
   const [isKeyReleased, setIsKeyReleased] = useState(false);
   const productDetails = 
   {productImage,productTitle,productSubCategory,productCategory,productPrice,productLocation,priceCondition,adType,rentOrLeasePrice,productCondition,adDescription,adTags}
   console.log('productdetails',productDetails);
   const ProductDetails = {productDetails}
   const deleteTag = (index) => {
      setTags(prevState => prevState.filter((tag, i) => i !== index))
    }
   const onChange = (e) => {
   const { value } = e.target;
   setInput(value);
   };

   const handleChangeAdType = (event) => {
      setAdType(event.target.value);
    };
   const handleChangePriceCondition = (event) => {
      setPriceCondition(event.target.value);
    };
   const handleChangeRentORLease = (event) => {
   setRentOrLease(event.target.value);
   };
   const handleChangeProductCondition = (event) => {
      setProductCondition(event.target.value);
      };
   const onKeyDown = (e) => {
      const { key } = e;
      const trimmedInput = input.trim();
      
      if (key === ',' && trimmedInput.length && !adTags.includes(trimmedInput)) {
         e.preventDefault();
         setTags(prevState => [...prevState, trimmedInput]);
         setInput('');
      }
       
      if (key === "Backspace" && !input.length && adTags.length && isKeyReleased) {
         const tagsCopy = [...adTags];
         const poppedTag = tagsCopy.pop();
         e.preventDefault();
         setTags(tagsCopy);
         setInput(poppedTag);
      }
      
      setIsKeyReleased(false);
      };
      
      const onKeyUp = () => {
      setIsKeyReleased(true);
      }

      async function UploadImage(e) {
         e.preventDefault()
      
      const image =new FormData()
      for(var i = 0;i < productImage1.length ; i++)
        image.append('image',productImage1[i])
       console.log(image);
       await fetch('https://node.firehrm.com/FK/owner/productImage',{ 
       method: 'POST',
       body:image
   }).then((response)=>{
       console.log(response)
       return response.json()
   }).then((resp)=>{console.log('o',resp);
       setproductImage(resp.data.image); console.log('pI',productImage);
       Swal.fire("Image Added Successfully","","Success")
   } )
   .catch((error)=>{
      Swal.fire(error.message,"","error")
       console.log(error)
   })
   }
   console.log('1',productImage1);
   console.log('u',productImage);
      
      // const onSelectFile=(event)=>{
      //    const selectedFiles = event.target.files
      //    const selectedFilesArray = Array.from(selectedFiles)
      //    // console.log(selectedFilesArray)
      //    const imagesArray = selectedFilesArray.map((file)=>{
      //       return URL.createObjectURL(file)
      //    })
      //    console.log(imagesArray)
      //    // setselectedImages(imagesArray)
        

      //    setselectedImages((previousImages)=>previousImages.concat(imagesArray))
        
      // }
      
  const [categoriesList, setCategoriesList] = useState('');

  async function getCategoriesList() {
    await axios({
      'method': 'GET',
      'url': `https://node.firehrm.com/FK/admin/getAll-category`,
      'headers': {
     'Authorization':token,
      'Content-Type': 'application/json',
      },
    }).then((data) => {
      console.log(data.data.data,'categoryconsole')
      setCategoriesList(data?.data?.data)
     
    }).catch((error)=>{
      console.log(error);
    })
    
   }
    useEffect(() => {
     getCategoriesList();
  }, []);

  
      function AdPost(e){
         e.preventDefault()
         fetch('https://node.firehrm.com/FK/owner/addProduct',{
             method:'POST',
             headers:{
                 'Authorization':token,
                 'Content-Type': 'application/json'
               },
             body: JSON.stringify(ProductDetails)
             })
             .then((response)=> response.json())
             
             .then((data) => {
                 console.log('Success:', data)
                 if(data.message == 'add product successfully'){
                  localStorage.setItem('productId',data.result._id)
                  Swal.fire(data.message)
                  navToNextPage()
                 }
             })
             .catch((error) => {
               Swal.fire(error.message,"","error")
             console.log('Error:', error);
             });
         }
      const nav = useNavigate()
         function navToNextPage(){
            nav('/choosePackageforPostAd')
         }

      
  return (
    <div>
        <section class="adpost-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-8">
                  <form class="adpost-form">
                     <div class="adpost-card">
                        <div class="adpost-title">
                           <h3>Ad Information</h3>
                        </div>
                        <div class="row">
                           <div class="col-lg-12">
                              <div class="form-group">
                                 <label class="form-label">Product Title</label>
                                 <input onChange={(e)=>setProductTitle(e.target.value)} type="text" class="form-control" placeholder="Type your product title here"/>
                              </div>
                           </div>
                           <div class="col-lg-12">
                          
                              <div class="form-group">
                                 <label class="form-label">Product Image</label>
                                 <input multiple onChange={(e)=>setProductImage1(e.target.files)} type="file" class="form-control br-blue border border-secondary mb-5"/>
                                 <button class="btn btn-inline" onClick={(e)=>UploadImage(e)}>upload image</button>
                              </div>
                           </div>
                           <div class="col-lg-4">
                              <div class="form-group">
                                 <label class="form-label">Product Category</label>
                                 
                                 <select onChange={(e)=>setProductCategory(e.target.value)} class="form-control custom-select">
                                    <option selected>Select Category</option>
                                    {categoriesList && categoriesList?.map((x=>
                                    <option value={x?.categoryName}>{x?.categoryName}</option>
                                    ))}
                                 </select>
                               
                              </div>
                           </div>
                           <div class="col-lg-4">
                              <div class="form-group">
                                 <label class="form-label">Product Sub-Category</label>
                                 
                                 <select onChange={(e)=>setSubProductCategory(e.target.value)} class="form-control custom-select">
                                    <option selected>Select Category</option>
                                    {categoriesList && categoriesList?.map((x=>
                                    <>
                                    {productCategory == x?.categoryName && x?.subCategoryName.map((y=>
                                    <option value={y}>{y}</option>
                                    ))}
                                    </>
                                    ))}
                                 </select>
                              
                              </div>
                           </div>
                           <div class="col-lg-4">
                              <div class="form-group">
                                 <label class="form-label">Price</label>
                                 <input onChange={(e)=>setProductPrice(e.target.value)} type="number" class="form-control" placeholder="Enter your pricing amount"/>
                              </div>
                           </div>
                           <div class="col-md-4 col-lg-4 mt-5 mb-5">
                           <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                 Ad Type
                              </InputLabel>
                              <NativeSelect
                                 defaultValue={"selectType"}
                                 inputProps={{
                                    name: 'Ad Type',
                                    id: 'uncontrolled-native',
                                 }}
                                 onChange={handleChangeAdType}
                              >  <option value={'selectType'}></option>
                                 <option value={'sale'}>Sale</option>
                                 <option value={'rent'}>Rent</option>
                                 <option value={'lease'}>Lease</option>
                              </NativeSelect>
                              </FormControl>
                           </Box>
                           </div>
                           <div class="col-md-4 col-lg-4 mt-5 mb-5">
                           <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                 Product Condition
                              </InputLabel>
                              <NativeSelect
                                 defaultValue={"chooseCondition"}
                                 inputProps={{
                                    name: 'Product Condition',
                                    id: 'uncontrolled-native',
                                 }}
                                 onChange={handleChangeProductCondition}
                              >  <option value={'chooseCondition'}></option>
                                 <option value={'used'}>Used</option>
                                 <option value={'new'}>New</option>
                              </NativeSelect>
                              </FormControl>
                           </Box>
                           </div>
                           <div class="col-md-4 col-lg-4 mt-5 mb-5">
                           <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                 Price Condition
                              </InputLabel>
                              <NativeSelect
                                 defaultValue={"choosePriceCondition"}
                                 inputProps={{
                                    name: 'Price Condition',
                                    id: 'uncontrolled-native',
                                 }}
                                 onChange={handleChangePriceCondition}
                              >  <option value={'choosePriceCondition'}></option>
                                 <option value={'fixed'}>Fixed</option>
                                 <option value={'negotiable'}>Negotiable</option>
                              </NativeSelect>
                              </FormControl>
                           </Box>
                           </div>
                           <div class="col-lg-6 mt-3 mb-5">
                              <div class="form-group">
                                 <label class="form-label">Product Location</label>
                                 <input onChange={(e)=>setProductLocation(e.target.value)} type="text" class="form-control" placeholder="Type your product location here"/>
                              </div>
                           </div>
                           <div class="col-lg-6 mt-5">
                           <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth>
                              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              (Only for Rent/Lease) Price per 
                              </InputLabel>
                              <NativeSelect
                                 defaultValue={"pricePer"}
                                 inputProps={{
                                    name: 'Rent/Lease Price',
                                    id: 'uncontrolled-native',
                                 }}
                                 onChange={handleChangeRentORLease}
                              >  <option value={'pricePer'}></option>
                                 <option value={'daily'}>Daily</option>
                                 <option value={'weekly'}>Weekly</option>
                                 <option value={'monthly'}>Monthly</option>
                                 <option value={'yearly'}>Yearly</option>
                                 <option value={'sale'}>For Sale</option>
                              </NativeSelect>
                              </FormControl>
                           </Box>
                           </div>
                           <div class="col-lg-12">
                              <div class="form-group">
                                 <label class="form-label">ad description</label>
                                 <textarea onChange={(e)=>setAdDescription(e.target.value)} class="form-control" placeholder="Describe your message"></textarea></div>
                           </div>
                           <div class="col-lg-12">
                           <label class="form-label mt-3 mb-3">Ad Tags</label>
                           <div className="container1">
                           {adTags.map((adTags, index) => (
                           <div className="tag1">
                              {adTags}
                              <button className='button1' onClick={() => deleteTag(index)}>x</button>
                           </div>
                           ))}
                              <input className='input1'
                              value={input}
                              placeholder="Enter a tag put comma(,) to close tag"
                              onKeyDown={onKeyDown}
                              onKeyUp={onKeyUp}
                              onChange={onChange}
                              />
                           </div>
                        </div>
                     </div>
                     {/* <div class="adpost-card">
                        <div class="adpost-title">
                           <h3>Author Information</h3>
                        </div>
                        <div class="row">
                           <div class="col-lg-6">
                              <div class="form-group"><label class="form-label">Name</label><input type="text" class="form-control" placeholder="Your Name"/></div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group"><label class="form-label">Email</label><input type="email" class="form-control" placeholder="Your Email"/></div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group"><label class="form-label">Number</label><input type="number" class="form-control" placeholder="Your Number"/></div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group"><label class="form-label">Address</label><input type="text" class="form-control" placeholder="Your Address"/></div>
                           </div>
                        </div>
                     </div> */}
                     {/* <div class="adpost-card">
                        <div class="adpost-title">
                           <h3>Plan Information</h3>
                        </div>
                        <ul class="adpost-plan-list">
                           <li>
                              <div class="adpost-plan-content">
                                 <h6>Free Plan - <span>Submit 5 Ad Listings</span></h6>
                                 <p>Lorem ipsum dolor sit amet consectetur adipisicing elit Delectus minus Eaque corporis accusantium incidunt officiis deleniti.</p>
                              </div>
                              <div class="adpost-plan-meta">
                                 <h3>$00.00</h3>
                                 <button class="btn btn-outline"><span>Select</span></button>
                              </div>
                           </li>
                           <li>
                              <div class="adpost-plan-content">
                                 <h6>Standerd Plan - <span>Submit 10 Ad Listings</span></h6>
                                 <p>Lorem ipsum dolor sit amet consectetur adipisicing elit Delectus minus Eaque corporis accusantium incidunt officiis deleniti.</p>
                              </div>
                              <div class="adpost-plan-meta">
                                 <h3>$23.00</h3>
                                 <button  class="btn btn-outline"><span>Select</span></button>
                              </div>
                           </li>
                           <li>
                              <div class="adpost-plan-content">
                                 <h6>Premium Plan - <span>Unlimited Ad Listings</span></h6>
                                 <p>Lorem ipsum dolor sit amet consectetur adipisicing elit Delectus minus Eaque corporis accusantium incidunt officiis deleniti.</p>
                              </div>
                              <div class="adpost-plan-meta">
                                 <h3>$43.00</h3>
                                 <button class="btn btn-outline"><span>Select</span></button>
                              </div>
                           </li>
                        </ul>
                     </div> */}
                     <div class="adpost-card pb-2">
                        <div class="adpost-agree">
                           <div class="form-group"><input type="checkbox" class="form-check"/></div>
                           <p>Send me Trade Email/SMS Alerts for people looking to buy mobile handsets in www By clicking "Post", you agree to our <a href="#">Terms of Use</a>and <a href="#">Privacy Policy</a>and acknowledge that you are the rightful owner of this item and using Trade to find a genuine buyer.</p>
                        </div>
                        <div class="form-group text-right"><button onClick={(e)=>AdPost(e)} class="btn btn-inline"><i class="fas fa-check-circle"></i><span>Submit Post</span></button></div>
                     </div>
                     </div>
                  </form>
               </div>
               <div class="col-lg-4">
                  <div class="account-card alert fade show">
                     <div class="account-title">
                        <h3>Safety Tips</h3>
                        <button data-dismiss="alert">close</button>
                     </div>
                     <ul class="account-card-text">
                        <li>
                           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit debitis odio perferendis placeat at aperiam.</p>
                        </li>
                        <li>
                           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit debitis odio perferendis placeat at aperiam.</p>
                        </li>
                        <li>
                           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit debitis odio perferendis placeat at aperiam.</p>
                        </li>
                        <li>
                           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit debitis odio perferendis placeat at aperiam.</p>
                        </li>
                        <li>
                           <p>Lorem ipsum dolor sit amet consectetur adipisicing elit debitis odio perferendis placeat at aperiam.</p>
                        </li>
                     </ul>
                  </div>
                  <div class="account-card alert fade show">
                     <div class="account-title">
                        <h3>Custom Offer</h3>
                        <button data-dismiss="alert">close</button>
                     </div>
                     <form class="account-card-form">
                        <div class="form-group"><input type="text" class="form-control" placeholder="Name"/></div>
                        <div class="form-group"><input type="email" class="form-control" placeholder="Email"/></div>
                        <div class="form-group"><textarea class="form-control" placeholder="Message"></textarea></div>
                        <div class="form-group"><button class="btn btn-inline"><i class="fas fa-paper-plane"></i><span>send Message</span></button></div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
