import React from 'react'
import Footer from '../COMPONENT/footer'
import MobileNav from '../COMPONENT/mobileNav'
import ProfileBanner from '../COMPONENT/profileBanner'
import ProfileDashboard from '../COMPONENT/profileDashboard'
import SideBar from '../COMPONENT/sideBar';
import ProfileDetails from "../COMPONENT/profileDetails";
import Header from '../COMPONENT/header'
import { Helmet } from 'react-helmet'
import MyAdsPart from '../COMPONENT/myAdsPart'

export default function MyAds() {
  return (
    <div>
        <Header/>
        <SideBar/>
        <MobileNav/>
        <ProfileBanner/>
        <ProfileDashboard/>
        <MyAdsPart/>
        <Footer/>
    <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
