import { Route, Routes } from "react-router-dom";
import Home from "./PAGES/home";
import Error404 from './PAGES/404.jsx';
import About from "./PAGES/about.jsx";
import ViewSingleAd from "./PAGES/viewSingleAd.jsx";
import AdListColumn1 from "./PAGES/adListColumn1.jsx";
import AdListColumn2 from "./PAGES/adListColumn2.jsx";
import AdListColumn3 from "./PAGES/adListColumn3.jsx";
import AdPost from "./PAGES/adPost.jsx";
import AdJobs from "./PAGES/adJobs.jsx";
import AdResume from "./PAGES/adResume.jsx";
import LoginForm from "./PAGES/loginForm.jsx";
import RegisterForm from "./PAGES/registerForm";
import ForgetPassword from "./PAGES/forgetPassword";
import Profile from "./PAGES/profile";
import ProfileSetting from "./PAGES/profileSetting";
import ChoosePackageforPostAd from "./PAGES/choosePackageforPostAd";
import MyAds from "./PAGES/myAds";
import WishListPage from "./PAGES/wishListPage";

function App() {
  
  return (

      <Routes>
      <Route exact path = '/' element={<Home/>}/>
      <Route path='/404' element={<Error404/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/viewSingleAd' element={<ViewSingleAd/>}/>
      <Route path='/adListColumn1' element={<AdListColumn1/>}/>
      <Route path='/adListColumn2' element={<AdListColumn2/>}/>
      <Route path='/adListColumn3' element={<AdListColumn3/>}/>
      <Route path='/adPost' element={<AdPost/>}/>
      <Route path='/adJobs' element={<AdJobs/>}/>
      <Route path='/adResume' element={<AdResume/>}/>
      <Route path='/loginForm' element={<LoginForm/>}/>
      <Route path='/registerForm' element={<RegisterForm/>}/>
      <Route path='/forgetPassword' element={<ForgetPassword/>}/>
      <Route path='/profile' element={<Profile/>}/>
      <Route path='/profilesetting' element={<ProfileSetting/>}/>
      <Route path='/choosePackageforPostAd' element={<ChoosePackageforPostAd/>}/>
      <Route path='/myAds' element={<MyAds/>}/>
      <Route path='/wishListPage' element={<WishListPage/>}/>
      </Routes>
    
  );
}

export default App;
