import React from 'react'

export default function AdResumeBanner() {
  return (
    <div>
        <section class="banner-part">
         <div class="container">
            <div class="banner-content">
               <h1>Add Resume</h1>
            </div>
         </div>
      </section>
    </div>
  )
}
