import React, {useState,useEffect} from 'react';
import { Helmet } from 'react-helmet'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function ProfileDetails() {
   const token = localStorage.getItem('token')
   const ID = localStorage.getItem('ID')
   const navigateToHome = useNavigate()
   const [profileDetails, setProfileDetails] = useState()
   const resp = ''
   useEffect(() => {
      // GET request using fetch inside useEffect React hook
      fetch('https://node.firehrm.com/FK/user/getById/'+ID)
          .then(response => response.json())
          .then(resp => {console.log('res:',resp.data[0])
          setProfileDetails(resp.data && resp.data[0])
          console.log(profileDetails)
         })                  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const deleteProfile = () =>{
   axios({
          'method':'delete',
          'url':'https://node.firehrm.com/FK/user/delete-profile/'+ID,
          headers:{
              'Authorization':token
          }
      }).then((response) => {
          console.log(response)
          localStorage.clear()
          navigateToHome('/')
          })
      .catch((error)=>{
          console.log(error)
      })
  }
 
  return (
    <div> 
         <section class="profile-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-6">
               <div class="account-card">
                     <div class="account-title">
                        <h3>Membership</h3>
                        <a href="profileSetting">Edit</a>
                        {console.log(profileDetails)}
                     </div>
                     <ul class="account-card-list">
                        <li>
                           <h5>Status</h5>
                           <p>{profileDetails ?.accountType}</p>
                        </li>
                        <li>
                           <h5>Joined</h5>
                           <p>{profileDetails ?.createdAt}</p>
                        </li>
                        <li>
                           <h5>Sell</h5>
                           <p>4,587</p>
                        </li>
                        <li>
                           <h5>Sold</h5>
                           <p>97,325</p>
                        </li>
                     </ul>
                  </div>
                  <div class="account-card">
                     <div class="account-title">
                        <h3>Billing Address</h3>
                        <a href="profileSetting">Edit</a>
                     </div>
                     <ul class="account-card-list">
                        <li>
                           <h5>Address:</h5>
                           <p>{profileDetails?.address}</p>
                        </li>
                        <li>
                           <h5>City:</h5>
                           <p>{profileDetails?.city}</p>
                        </li>
                        <li>
                           <h5>State:</h5>
                           <p>{profileDetails?.state}</p>
                        </li>
                        <li>
                           <h5>Post Code:</h5>
                           <p>{profileDetails?.pincode}</p>
                        </li>
                        <li>
                           <h5>Country:</h5>
                           <p>{profileDetails?.country}</p>
                        </li>
                     </ul>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="account-card">
                     <div class="account-title">
                     <h3>Contact Info</h3>
                     <a href="profileSetting">Edit</a>
                     </div>
                     <ul class="account-card-list">
                     <li>
                        <h5>Website:</h5>
                        <p>{profileDetails ?.website}</p>
                     </li>
                     <li>
                        <h5>Email:</h5>
                        <p>{profileDetails ?.email}</p>
                     </li>
                     <li>
                        <h5>Phone:</h5>
                        <p>{profileDetails ?.phoneNumber}</p>
                     </li>
                     <li>
                        <h5>Skype:</h5>
                        <p>{profileDetails ?.skypeId}</p>
                     </li>
                     </ul>
                  </div>
                  <div class="account-card">
                     <div class="account-title">
                        <h3>Activities</h3>
                        <a href="profileSetting">Edit</a>
                     </div>
                     <ul class="account-card-list"> 
                        <li>
                           <h5>Wishlist:</h5>
                           <p></p>
                        </li>
                        <li>
                           <h5>Message:</h5>
                           <p></p>
                        </li>
                        <li>
                           <h5>Notification:</h5>
                           <p></p>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div className='col-lg-8'></div>
            <div class="col-lg-2">
                  <button class="btn btn-inline alert fade show dash-focus dash-list" 
                  onClick={(e)=>{deleteProfile(e)}} 
                  >Delete Profile</button>
               <div className='col-lg-2'></div>
            </div>
         </div>
      </section>
      <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
