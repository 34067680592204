import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

export default function ProfileUpdate() {
   const [profileDetails, setProfileDetails] = useState()
    const [profileImage,setProfileImage]=useState(profileDetails?.profileImage);
    const [profileImage1,setProfileImage1]=useState('');
    const [userName, setProfileName] = useState(profileDetails?.userName)
    const [accountType, setAccountType] = useState(profileDetails?.accountType)
    const [address, setBillingAddress] = useState(profileDetails?.address)
    const [city, setBillingCity] = useState(profileDetails?.city)
    const [state, setBillingState] = useState(profileDetails?.state)
    const [pincode, setBillingPincode] = useState(profileDetails?.pincode)
    const [country, setBillingCountry] = useState(profileDetails?.country)
    const [website, setWebsite] = useState(profileDetails?.website)
    const [phoneNumber, setPhoneNumber] = useState(profileDetails?.phoneNumber)
    const [email, setEmail] = useState(profileDetails?.email)
    const [skypeId, setSkypeId] = useState(profileDetails?.skypeId)
    const token = localStorage.getItem('token')
    const ID = localStorage.getItem('ID')
    const updateProfile = useNavigate()
    
    useEffect(() => {
      // GET request using fetch inside useEffect React hook
      fetch('https://node.firehrm.com/FK/user/getById/'+ID)
          .then(response => response.json())
          .then(resp => {console.log(resp,'response');
          setProfileDetails(resp.data && resp.data[0])

         })                  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

    const UpdateProfile = async (e) => {
        e.preventDefault();
        const updateProfileData ={profileImage,userName,accountType,address,city,state,pincode,country,website,phoneNumber,email,skypeId}
        {console.log(updateProfileData,'update')}
        let updateEmployeeData= await fetch('https://node.firehrm.com/FK/user/update-profile/'+ID,{
            method: 'PUT',
            headers: {
             'Authorization':token ,
              'Content-type': 'application/JSON'
            },
            
            body: JSON.stringify(updateProfileData)
        }).then((response)=>{
            console.log(response)
            
         //  updateProfile('/profile')
        }).catch((error)=>{
            console.log(error)
        })
        updateEmployeeData = await updateEmployeeData.json()
        console.log(updateEmployeeData)
    }
    
       async function UploadImage(e) {
          e.preventDefault()
        const image =new FormData()
        image.append('image',profileImage1[0])
        console.log(profileImage1)
        await fetch('https://node.firehrm.com/FK/user/image',{ 
        method: 'POST',
        body:image
    }).then((response)=>{
        console.log(response)
        return response.json()
    }).then((resp)=>{console.log(resp);setProfileImage(resp.data.image); console.log(profileImage);
      Swal.fire(resp.message)
   } )
    .catch((error)=>{
        console.log(error)
        Swal.fire(error.message)
    })
    }

  return (
    <div>
        <div class="setting-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="account-card alert fade show">
                     <div class="account-title">
                        <h3>Edit Profile</h3>
                        {/* <button data-dismiss="alert"><a href='/profile'>close</a></button> */}
                     </div>
                     <form class="setting-form">
                        <div class="row">
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Profile Name</label>
                                 <input placeholder={profileDetails?.userName} onChange={(e)=>setProfileName(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Account Type</label>
                              <select class="form-control" 
                              onChange={(e)=>setAccountType(e.target.value)} 
                              style={{background:'lightGrey'}}>
                                <option value="">Select Account Type</option>
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                              </select>
                            </div>
                           </div>
                           <div class="col-lg-9">
                              <div class="form-group">
                                 <label class="form-label">Profile Image</label>
                                 <input type="file" class="form-control" onChange={(e)=>
                                    setProfileImage1(e.target.files)}/>
                              </div>
                           </div>
                           <div class="col-lg-3">
                               <button class="btn btn-inline" onClick={(e)=>UploadImage(e)}>upload image</button>
                            </div>
                           <div class="col-lg-12">
                              <div class="form-group">
                                 <label class="form-label">Billing Address</label>
                                 <input placeholder={profileDetails?.address} onChange={(e)=>setBillingAddress(e.target.value)} style={{background:'lightGrey'}}  type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">City</label>
                                 <input placeholder={profileDetails?.city} onChange={(e)=>setBillingCity(e.target.value)}  style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">State</label>
                                 <input placeholder={profileDetails?.state} onChange={(e)=>setBillingState(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Post Code</label>
                                 <input placeholder={profileDetails?.pincode} onChange={(e)=>setBillingPincode(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Country</label>
                                 <input placeholder={profileDetails?.country} onChange={(e)=>setBillingCountry(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Website</label>
                                 <input placeholder={profileDetails?.website} onChange={(e)=>setWebsite(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Phone</label>
                                 <input placeholder={profileDetails?.phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Email-ID</label>
                                 <input placeholder={profileDetails?.email} onChange={(e)=>setEmail(e.target.value)} style={{background:'lightGrey'}} type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-6">
                              <div class="form-group">
                                 <label class="form-label">Skype-ID</label>
                                 <input placeholder={profileDetails?.skypeId} onChange={(e)=>setSkypeId(e.target.value)} style={{background:'lightGrey'}}    type="text" class="form-control"/>
                              </div>
                           </div>
                           <div class="col-lg-12">
                               <button onClick={(e)=>UpdateProfile(e)} class="btn btn-inline">
                                  <i class="fas fa-user-check"></i>
                                  <span>update profile</span>
                               </button>
                            </div>
                       </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}
