import React from 'react'

export default function AboutAdvantage() {
  return (
    <div>
        <section class="best-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>How We Best for Advertising?</h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-6 col-lg-4 col-xl-4">
                  <div class="best-card">
                     <div class="best-icon"><i class="fas fa-boxes"></i></div>
                     <div class="best-content">
                        <h4>eye catching feature</h4>
                        Lorem ipsum dolor sit amet elit Ea fuga velit itaque animi debitis earum temporibus iusto dolore delectus. 
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4 col-xl-4">
                  <div class="best-card">
                     <div class="best-icon"><i class="fas fa-lock"></i></div>
                     <div class="best-content">
                        <h4>strong data sequrity</h4>
                        Lorem ipsum dolor sit amet elit Ea fuga velit itaque animi debitis earum temporibus iusto dolore delectus. 
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4 col-xl-4">
                  <div class="best-card">
                     <div class="best-icon"><i class="fas fa-shield-alt"></i></div>
                     <div class="best-content">
                        <h4>Commercial Support</h4>
                        Lorem ipsum dolor sit amet elit Ea fuga velit itaque animi debitis earum temporibus iusto dolore delectus. 
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4 col-xl-4">
                  <div class="best-card">
                     <div class="best-icon"><i class="fas fa-bell"></i></div>
                     <div class="best-content">
                        <h4>Deadline Reminders</h4>
                        Lorem ipsum dolor sit amet elit Ea fuga velit itaque animi debitis earum temporibus iusto dolore delectus. 
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4 col-xl-4">
                  <div class="best-card">
                     <div class="best-icon"><i class="fas fa-dollar-sign"></i></div>
                     <div class="best-content">
                        <h4>easy get payment</h4>
                        Lorem ipsum dolor sit amet elit Ea fuga velit itaque animi debitis earum temporibus iusto dolore delectus. 
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4 col-xl-4">
                  <div class="best-card">
                     <div class="best-icon"><i class="fas fa-check-circle"></i></div>
                     <div class="best-content">
                        <h4>Verified Ad posting</h4>
                        Lorem ipsum dolor sit amet elit Ea fuga velit itaque animi debitis earum temporibus iusto dolore delectus. 
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
