import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import noProductImg from '../images/product/no-image.jpg'

export default function AdListCol3FilterAndDetails() {
   const nav =useNavigate()
   const [shortBy, setshortBy] = useState("descending");
   const token = localStorage.getItem('token')
   console.log(shortBy);
   const [minPrice, setminprice] = useState('');
   const [maxPrice, setmaxprice] = useState('');
   const x = useLocation()
   console.log(x);
   const y = x?.state?.categoryName
   const [ProductList, setproductList] = useState([]);
   // console.log(state?.state?.categoryName);
   const [sale, setsale] = useState('');
   const [rent, setrent] = useState('');
   const [lease, setlease] = useState('')
   const [City, setcity] = useState('')
   const [Like, setLike] = useState('false');
   const [Selectcity, setSelectCity] = useState([]);
   console.log(typeof(Selectcity));
   // var city = Selectcity;
   var city = [...new Set(Selectcity)]
   const [Category, setcategory] = useState('');
   console.log(Category);
   const [selectedCategory,setselectedCategory] = useState('')
   console.log(city);
   const category = [...selectedCategory]
   const [selectedSubCategory,setselectedSubCategory] = useState('')
   const product = [...selectedSubCategory]
    console.log(product);
   const adType = [sale,rent,lease]
   const wish = {interest:Like}
   
   function AddWishList(e,){
      e.preventDefault()
      fetch('https://node.firehrm.com/FK/user/create-favproduct/:productId',{
          method:'POST',
          headers:{
              'Authorization':token,
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(wish)
          })
          .then((response)=> response.json())
          
          .then((data) => {
              console.log('Success:', data)
              if(data.message == 'add product successfully'){
               localStorage.setItem('productId',data.result._id)

              }
          })
          .catch((error) => {
          console.log('Error:', error);
          });
      }

   async function getallcity() {
      await axios({
        'method': 'GET',
        'url': 'https://node.firehrm.com/FK/owner/getAll-cities',
        'headers': {
       
        'Content-Type': 'application/json',
        },
      }).then((data) => {
        console.log(data.data.data,'city')
        setcity(data?.data?.data)
       
      }).catch((error)=>{
        console.log(error);
      })
      
     }

      useEffect(() => {
         getallcity();
      }, []);

      async function getallcategory() {
         await axios({
           'method': 'GET',
           'url': 'https://node.firehrm.com/FK/admin/getAll-category',
           'headers': {
          
           'Content-Type': 'application/json',
           },
         }).then((data) => {
           console.log(data.data.data,'category')
           setcategory(data?.data?.data)
          
         }).catch((error)=>{
           console.log(error);
         })
         
        }
   
         useEffect(() => {
            getallcategory();
         }, []);
   

   async function getProduct() {
      await axios({
        'method': 'GET',
        'url': 'https://node.firehrm.com/FK/owner/AllProduct-sortMenuFilter/'+shortBy,
        'headers': {
       
        'Content-Type': 'application/json',
        },
      }).then((data) => {
        console.log(data.data.data,'categoryconsole')
        setproductList(data?.data?.data)
       
      }).catch((error)=>{
        console.log(error);
      })
      
     }

      useEffect(() => {
         getProduct();
      }, [shortBy]);

      

   function PriceSearch(e){
      e.preventDefault()
      fetch('https://node.firehrm.com/FK/owner/price-basedSearch',{
          method:'POST',
          headers:{
            //   'Authorization':token,
              'Content-Type': 'application/json'
            },
          body: JSON.stringify({minPrice,maxPrice})
          })
          .then((response)=> response.json())
          
          .then((data) => {
              console.log('Success price:', data)
               setproductList(data?.data)
          })
          .catch((error) => {
          console.log('Error:', error);
          });
      }

      function AdTypeSearch(e){
         e.preventDefault()
         fetch('https://node.firehrm.com/FK/owner/adType-basedFilter',{
             method:'POST',
             headers:{
               //   'Authorization':token,
                 'Content-Type': 'application/json'
               },
             body: JSON.stringify({adType})
             })
             .then((response)=> response.json())
             
             .then((data) => {
                 console.log('Success adtype:', data?.arr)
                  setproductList(data?.arr)
             })
             .catch((error) => {
             console.log('Error:', error);
             });
         }

         function CitySearch(e){
            e.preventDefault()
            fetch('https://node.firehrm.com/FK/owner/city-basedFilter',{
                method:'POST',
                headers:{
                  //   'Authorization':token,
                    'Content-Type': 'application/json'
                  },
                body: JSON.stringify({city})
                })
                .then((response)=> response.json())
                
                .then((data) => {
                    console.log('Success city:', data)
                    setproductList(data?.arr)
                })
                .catch((error) => {
                console.log('Error:', error);
                });
            }

            function Categorysearch(e){
               e.preventDefault()
               fetch('https://node.firehrm.com/FK/owner/category-basedFilter',{
                   method:'POST',
                   headers:{
                     //   'Authorization':token,
                       'Content-Type': 'application/json'
                     },
                   body: JSON.stringify({category})
                   })
                   .then((response)=> response.json())
                   
                   .then((data) => {
                       console.log('Success category:', data)
                       setproductList(data?.arr)
                   })
                   .catch((error) => {
                   console.log('Error:', error);
                   });
               }

               function SubCategorysearch(e){
                  e.preventDefault()
                  fetch('https://node.firehrm.com/FK/owner/product-basedFilter',{
                      method:'POST',
                      headers:{
                        //   'Authorization':token,
                          'Content-Type': 'application/json'
                        },
                      body: JSON.stringify({product})
                      })
                      .then((response)=> response.json())
                      
                      .then((data) => {
                          console.log('Success subcategory:', data)
                          setproductList(data?.arr)
                      })
                      .catch((error) => {
                      console.log('Error:', error);
                      });
                  }

            function Navtoproduct(e,x){
               e.preventDefault()
               console.log(x);
               nav('/viewSingleAd',{state:x})
         }

  return (
    <div>
        <section class="inner-section ad-list-part">
         <div class="container">
            <div class="row content-reverse">
               <div class="col-lg-4 col-xl-3">
                  <div class="row">
                     <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">Filter by Price</h6>
                           <form class="product-widget-form">
                              <div class="product-widget-group"><input type="tel" onChange={(e)=>setminprice(e.target.value)} placeholder="min - 00"/><input onChange={(e)=>setmaxprice(e.target.value)} type="tel" placeholder="max - 1B"/></div>
                              <button onClick={(e)=>PriceSearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">Filter by type</h6>
                           <form class="product-widget-form">
                              <ul class="product-widget-list">
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" onChange={(e)=>setsale(e.target.value)} value='sale' id="chcek1"/></div>
                                    <label class="product-widget-label" for="chcek1"><span class="product-widget-type sale">sales</span><span class="product-widget-number">(15)</span></label>
                                 </li>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" onChange={(e)=>setrent(e.target.value)} value="rent" id="chcek2"/></div>
                                    <label class="product-widget-label" for="chcek2"><span class="product-widget-type rent">rental</span><span class="product-widget-number">(25)</span></label>
                                 </li>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" onChange={(e)=>setlease(e.target.value)} value="lease" id="chcek3"/></div>
                                    <label class="product-widget-label" for="chcek3"><span class="product-widget-type booking">Lease</span><span class="product-widget-number">(35)</span></label>
                                 </li>
                              </ul>
                              <button onClick={(e)=>AdTypeSearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div>
                     {/* <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">Filter by rating</h6>
                           <form class="product-widget-form">
                              <ul class="product-widget-list">
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" id="chcek4"/></div>
                                    <label class="product-widget-label" for="chcek4"><span class="product-widget-star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i></span><span class="product-widget-number">(45)</span></label>
                                 </li>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" id="chcek5"/></div>
                                    <label class="product-widget-label" for="chcek5"><span class="product-widget-star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="far fa-star"></i></span><span class="product-widget-number">(55)</span></label>
                                 </li>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" id="chcek6"/></div>
                                    <label class="product-widget-label" for="chcek6"><span class="product-widget-star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i></span><span class="product-widget-number">(65)</span></label>
                                 </li>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" id="chcek7"/></div>
                                    <label class="product-widget-label" for="chcek7"><span class="product-widget-star"><i class="fas fa-star"></i><i class="fas fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i></span><span class="product-widget-number">(75)</span></label>
                                 </li>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox"><input type="checkbox" id="chcek8"/></div>
                                    <label class="product-widget-label" for="chcek8"><span class="product-widget-star"><i class="fas fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i></span><span class="product-widget-number">(85)</span></label>
                                 </li>
                              </ul>
                              <button type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div> */}
                     <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">Filter by cities</h6>
                           <form class="product-widget-form">
                              {/* <div class="product-widget-search"><input type="text" placeholder="Search"/></div> */}
                              <ul class="product-widget-list product-widget-scroll">
                                 {City && City.map(x=>
                                 <>
                                 {x?.city && x?.city.map(y=>
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox">
                                       <input 
                                       onChange={(e)=>setSelectCity([...Selectcity,
                                       y?.cityName])} value={y.cityName} type="checkbox" id="chcek9"/></div>
                                    <label class="product-widget-label" for="chcek9"><span class="product-widget-text">{y.cityName}</span><span class="product-widget-number"></span></label>
                                 </li>
                                 )}
                                 </>
                                 )}
                              </ul>
                              <button onClick={(e)=>CitySearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">Filter by Category</h6>
                           <form class="product-widget-form">
                              {/* <div class="product-widget-search"><input type="text" placeholder="Search"/></div> */}
                              <ul class="product-widget-list product-widget-scroll">
                                 {Category && Category.map(x=>
                              
                                 <li class="product-widget-item">
                                    <div class="product-widget-checkbox">
                                       <input 
                                       onChange={(e)=>setselectedCategory([...selectedCategory,
                                       x?.categoryName])} value={x?.categoryName} type="checkbox" id="chcek9"/></div>
                                    <label class="product-widget-label" for="chcek9"><span class="product-widget-text">{x?.categoryName}</span><span class="product-widget-number"></span></label>
                                 </li>
                                 )}
                              </ul>
                              <button onClick={(e)=>Categorysearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">Filter by Sub-Category</h6>
                           <form class="product-widget-form">
                              {/* <div class="product-widget-search"><input type="text" placeholder="Search"/></div> */}
                              <ul class="product-widget-list product-widget-scroll">
                                 {Category && Category.map(x=>
                                  <>
                                  {x?.subCategoryName && x?.subCategoryName.map((y=>
                                  <li class="product-widget-item">
                                    <div class="product-widget-checkbox">
                                       <input 
                                       onChange={(e)=>setselectedSubCategory([...selectedSubCategory,
                                       y])} value={y} type="checkbox" id="chcek9"/></div>
                                    <label class="product-widget-label" for="chcek9"><span class="product-widget-text">{y}</span><span class="product-widget-number"></span></label>
                                 </li>
                                 ))}
                                 </>

                                 )}
                              </ul>
                              <button onClick={(e)=>SubCategorysearch(e)} type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div>
                     {/* <div class="col-md-6 col-lg-12">
                        <div class="product-widget">
                           <h6 class="product-widget-title">filter by category</h6>
                           <form class="product-widget-form">
                              <div class="product-widget-search"><input type="text" placeholder="search"/></div>
                              <ul class="product-widget-list product-widget-scroll">
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>electronics (234) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">mixer (56)</a></li>
                                       <li><a href="#">freez (78)</a></li>
                                       <li><a href="#">LED tv (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>automobiles (767) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">private car (56)</a></li>
                                       <li><a href="#">motorbike (78)</a></li>
                                       <li><a href="#">truck (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>properties (456) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">free land (56)</a></li>
                                       <li><a href="#">apartment (78)</a></li>
                                       <li><a href="#">shop (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>fashion (356) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">jeans (56)</a></li>
                                       <li><a href="#">t-shirt (78)</a></li>
                                       <li><a href="#">jacket (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>gadgets (768) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">computer (56)</a></li>
                                       <li><a href="#">mobile (78)</a></li>
                                       <li><a href="#">drone (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>furnitures (977) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">chair (56)</a></li>
                                       <li><a href="#">sofa (78)</a></li>
                                       <li><a href="#">table (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>hospitality (124) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">jeans (56)</a></li>
                                       <li><a href="#">t-shirt (78)</a></li>
                                       <li><a href="#">jacket (78)</a></li>
                                    </ul>
                                 </li>
                                 <li class="product-widget-dropitem">
                                    <button type="button" class="product-widget-link"><i class="fas fa-tags"></i>agriculture (565) </button>
                                    <ul class="product-widget-dropdown">
                                       <li><a href="#">jeans (56)</a></li>
                                       <li><a href="#">t-shirt (78)</a></li>
                                       <li><a href="#">jacket (78)</a></li>
                                    </ul>
                                 </li>
                              </ul>
                              <button type="button" class="product-widget-btn"><i class="fas fa-search"></i><span>search</span></button>
                           </form>
                        </div>
                     </div>*/}
                  </div> 
               </div>
               <div class="col-lg-8 col-xl-9">
                  <div class="row">
                     <div class="col-lg-12">
                        <div class="header-filter">
                           <div class="filter-show">
                              <label class="filter-label">Show :</label>
                              <select class="custom-select filter-select">
                                 <option value="1">12</option>
                                 <option value="2">24</option>
                                 <option value="3">36</option>
                              </select>
                           </div>
                           <div class="filter-short">

                        
                              <label class="filter-label">Sort by :</label>
                              <select onChange={(e)=>setshortBy(e.target.value)} class="custom-select filter-select">
                                 {/* <option defaultValue>default</option> */}
                                 <option value="descending">High to low Price</option>
                                 <option value="ascending">Low to high Price</option>
                                 <option value="mostViewed">Most Viewed</option>
                                 <option defaultValue ="new">New Product</option>
                              </select>
                           </div>
                           {/* <div class="filter-action"><a href="ad-list-column3.html" title="Three Column" class="active"><i class="fas fa-th"></i></a><a href="ad-list-column2.html" title="Two Column"><i class="fas fa-th-large"></i></a><a href="ad-list-column1.html" title="One Column"><i class="fas fa-th-list"></i></a></div> */}
                        </div>
                     </div>
                  </div>
                  {/* <div class="row">
                     <div class="col-lg-12">
                        <div class="ad-feature-slider slider-arrow">
                           <div class="feature-card">
                              <a href="#" class="feature-img"><img src="images/product/10.jpg" alt="feature"/></a>
                              <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                              <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                              <div class="feature-content">
                                 <ol class="breadcrumb feature-category">
                                    <li><span class="flat-badge rent">rent</span></li>
                                    <li class="breadcrumb-item"><a href="#">automobile</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">private car</li>
                                 </ol>
                                 <h3 class="feature-title"><a href="ad-details-grid.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                                 <div class="feature-meta"><span class="feature-price">$1200<small>/Monthly</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                              </div>
                           </div>
                           <div class="feature-card">
                              <a href="#" class="feature-img"><img src="images/product/01.jpg" alt="feature"/></a>
                              <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                              <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                              <div class="feature-content">
                                 <ol class="breadcrumb feature-category">
                                    <li><span class="flat-badge booking">booking</span></li>
                                    <li class="breadcrumb-item"><a href="#">Property</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">House</li>
                                 </ol>
                                 <h3 class="feature-title"><a href="ad-details-grid.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                                 <div class="feature-meta"><span class="feature-price">$800<small>/perday</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                              </div>
                           </div>
                           <div class="feature-card">
                              <a href="#" class="feature-img"><img src="images/product/08.jpg" alt="feature"/></a>
                              <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                              <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                              <div class="feature-content">
                                 <ol class="breadcrumb feature-category">
                                    <li><span class="flat-badge sale">sale</span></li>
                                    <li class="breadcrumb-item"><a href="#">gadget</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">iphone</li>
                                 </ol>
                                 <h3 class="feature-title"><a href="ad-details-grid.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                                 <div class="feature-meta"><span class="feature-price">$1150<small>/Negotiable</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                              </div>
                           </div>
                           <div class="feature-card">
                              <a href="#" class="feature-img"><img src="images/product/06.jpg" alt="feature"/></a>
                              <div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>
                              <button type="button" class="feature-wish"><i class="fas fa-heart"></i></button>
                              <div class="feature-content">
                                 <ol class="breadcrumb feature-category">
                                    <li><span class="flat-badge sale">sale</span></li>
                                    <li class="breadcrumb-item"><a href="#">automobile</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">cycle</li>
                                 </ol>
                                 <h3 class="feature-title"><a href="ad-details-grid.html">Unde eveniet ducimus nostrum maiores soluta temporibus ipsum dolor sit amet.</a></h3>
                                 <div class="feature-meta"><span class="feature-price">$455<small>/fixed</small></span><span class="feature-time"><i class="fas fa-clock"></i>56 minute ago</span></div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> */}
                  <div class="row">
        {ProductList && ProductList.map((x =>
                     <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                     <div class="product-card mb-5">
                     <button onClick={(e)=>Navtoproduct(e,x?._id)} class="product-media">
                        <div class="product-img">{x?.productDetails?.productImage?.[0]?<img src={x?.productDetails?.productImage?.[0]} width={250} height={178} alt="product"/>:<img src={noProductImg} width={250} height={178} alt="product"/>}</div>
                        {x.subscriptionPlanName == "Standard Plan" ?<div class="cross-vertical-badge product-badge">
                           <i class="fas fa-clipboard-check"></i><span>recommend</span>
                        </div>:""}  
                        {x.subscriptionPlanName == "Premium Plan" ?<div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>:""}
                        <div class="product-type">
                              {x?.productDetails?.adType == 'sale'?<span class="flat-badge sale">sale</span>:''}
                              {x?.productDetails?.adType == 'rent'?<span class="flat-badge rent">rent</span>:''}
                              {x?.productDetails?.adType == 'lease'?<span class="flat-badge booking">Lease</span>:''}
                        </div>
                        <ul class="product-action">
                           <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                           <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                           <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                        </ul>
                     </button>
                     <div class="product-content">
                     <button onClick={(e)=>Navtoproduct(e,x?._id)}>
                        <ol class="breadcrumb product-category">
                           <li><i class="fas fa-tags"></i></li>
                           <li class="breadcrumb-item"><button onClick={(e)=>Navtoproduct(e,x?._id)}>{x?.productDetails?.productCategory}</button></li>
                           <li class="breadcrumb-item active" aria-current="page">{x?.productDetails?.productSubCategory}</li>
                        </ol>
                     </button>
                        <h5 class="product-title"><button onClick={(e)=>Navtoproduct(e,x?._id)}>{x?.productDetails?.productTitle}</button></h5>
                        <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>{x?.productDetails?.productLocation},{x?.productOwnerDetails?.state}</span><br/><span><i class="fas fa-clock"></i>{x?.createdAt}</span></div>
                        
                        <div class="product-info">
                           <h5 class="product-price">${x?.productDetails?.productPrice}<span>/{x?.productDetails?.priceCondition}</span></h5>
                           <div class="product-btn"><button title="Compare" class="fas fa-compress"></button><button 
                            onClick={(e)=>AddWishList(e)} 
                           type="button" title="Wishlist" class="far fa-heart"></button></div>
                        </div>
                     </div>
                  </div>
                 
                     </div>
                     ))}
                 
                     
                  </div>
                  <div class="row">
                     <div class="col-lg-12">
                        <div class="footer-pagection">
                           <p class="page-info">Showing 12 of 60 Results</p>
                           <ul class="pagination">
                              <li class="page-item"><a class="page-link" href="#"><i class="fas fa-long-arrow-alt-left"></i></a></li>
                              <li class="page-item"><a class="page-link active" href="#">1</a></li>
                              <li class="page-item"><a class="page-link" href="#">2</a></li>
                              <li class="page-item"><a class="page-link" href="#">3</a></li>
                              <li class="page-item">...</li>
                              <li class="page-item"><a class="page-link" href="#">67</a></li>
                              <li class="page-item"><a class="page-link" href="#"><i class="fas fa-long-arrow-alt-right"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
