import React,{useState,useEffect} from 'react'
import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import noProductImg from '../images/product/no-image.jpg'

export default function RecomendAds() {
   const nav = useNavigate()
   const [StandardAds, setStandardAds] = useState('');

   async function getStandardAds() {
     await axios({
       'method': 'GET',
       'url': `https://node.firehrm.com/FK/owner/filter-forStandardPackage`,
       'headers': {
      // 'Authorization':token,
       'Content-Type': 'application/json',
       },
     }).then((data) => {
       console.log(data)
        setStandardAds(data.data.data)
     }).catch((error)=>{
       console.log(error);
     })
     };
   
     useEffect(()=>{getStandardAds()},[])

     const state = {
      responsive: {
          0: {
              items: 1,
          },
          400: {
              items: 2,
          },
          740: {
              items: 3,
          },
          940: {
              items: 4,
          },
      },
  }

  function Navtoproduct(e,x){
   e.preventDefault()
   console.log(x);
   nav('/viewSingleAd',{state:x})

  }

  return ( 
    <div>
      {StandardAds.length > 0 &&
        <section class="section recomend-part mb-5" stule={{padding:'0'}}>
         <div class="container">
            <div class="row">
               <div class="col-lg-12">
                  <div class="section-center-heading">
                     <h2>Our Recommend <span>Ads</span></h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit aspernatur illum vel sunt libero voluptatum repudiandae veniam maxime tenetur.</p>
                  </div>
               </div>
            </div>
            {StandardAds?
                <OwlCarousel items={5}
                className="owl-theme"
                loop
                nav
                margin={3}
                autoplay={true}
                dots={false}
                responsive={state.responsive}
                animateOut={'fadeOut'}
                animateIn={'flipInX'}>
                   {StandardAds?.map((x=>
                   <>
                  <button button onClick={(e)=>Navtoproduct(e,x?._id)} class="row">
                 
               <div class="col-md-12"> 
                  <div class="recomend-slider slider-arrow">
                     <div class="product-card" style={{width:'250px'}}>
                        <div class="product-media" style={{width:'250px'}}>
                           <div class="product-img">{x?.productDetails?.productImage?.[0]?<img src={x?.productDetails?.productImage?.[0]} width={250} height={178} alt="product"/>:<img src={noProductImg} width={250} height={178} alt="product"/>}</div>
                           {x.subscriptionPlanName == "Standard Plan" ?<div class="cross-vertical-badge product-badge">
                           <i class="fas fa-clipboard-check"></i><span>recommend</span>
                        </div>:""}  
                        {x.subscriptionPlanName == "Premium Plan" ?<div class="cross-inline-badge feature-badge"><span>featured</span><i class="fas fa-book-open"></i></div>:""}
                           <div class="product-type">
                              {x?.productDetails?.adType == 'sale'?<span class="flat-badge sale">sale</span>:''}
                              {x?.productDetails?.adType == 'rent'?<span class="flat-badge rent">rent</span>:''}
                              {x?.productDetails?.adType == 'lease'?<span class="flat-badge booking">Lease</span>:''}
                           </div>
                           <ul class="product-action">
                              <li class="view"><i class="fas fa-eye"></i><span>264</span></li>
                              <li class="click"><i class="fas fa-mouse"></i><span>134</span></li>
                              <li class="rating"><i class="fas fa-star"></i><span>4.5/7</span></li>
                           </ul>
                        </div>
                        <div class="product-content" style={{width:'250px'}}>
                           <ol class="breadcrumb product-category">
                              <li><i class="fas fa-tags"></i></li>
                              <li class="breadcrumb-item"><a>{x?.productDetails?.productCategory}</a></li>
                              <li class="breadcrumb-item active" aria-current="page">{x?.productDetails?.productSubCategory}</li>
                           </ol>
                           <h5 class="product-title"><a >{x?.productDetails?.productTitle}</a></h5>
                           <div class="product-meta"><span><i class="fas fa-map-marker-alt"></i>{x?.productDetails?.productLocation},{x?.productOwnerDetails?.state}</span><br/><span><i class="fas fa-clock"></i>{x?.createdAt}</span></div>
                           <div class="product-info">
                              <h5 class="product-price">${x?.productDetails?.productPrice}/<span>/{x?.productDetails?.priceCondition}</span></h5>
                              <div class="product-btn"><a  title="Compare" class="fas fa-compress"></a><button type="button" title="Wishlist" class="far fa-heart"></button></div>
                           </div>
                        </div>
                     </div>
                     
                  </div>
              </div>
            
            
            </button>
            </>
            ))}
             </OwlCarousel>:''} 
            {/* <div class="row">
               <div class="col-lg-12">
                  <div class="center-50"><a href="ad-list-column3.html" class="btn btn-inline"><i class="fas fa-eye"></i><span>view all recommend</span></a></div>
               </div>
            </div> */}
            
           
         </div>
      </section>
   }
    </div>
  )
}
