import React,{useState} from 'react'
import { useNavigate,Link } from 'react-router-dom'
import GoogleLogin from '../COMPONENT/googleLogin';
import FacebookLogin from '../COMPONENT/faceBookLogin';
import { Helmet } from 'react-helmet';
import firecolourWhite from "../images/logofcWhite.png"
import firecolourBlack from "../images/firecolourLogoBlack.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

export default function LoginForm() {
   const [phoneNumber, setPhoneNumber] = useState('')
   const [password, setPassword] = useState('')
   const nav = useNavigate()
   const UserLoginData = {phoneNumber,password}
   const [name, setName] = useState();

   function navToHome(){
      nav('/')
      Swal.fire("Login Successfull","","success")
    }
   function navToRegister(){
      Swal.fire("Not a signed-in account","","error")
   nav('/registerForm')
   }
   function navToSamePage(){
      Swal.fire("Password mismatch","","error")
   nav('/loginForm')
   }

    function UserLogin(e){
      e.preventDefault()
      fetch('https://node.firehrm.com/FK/user/login',{
          method:'POST',
          headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(UserLoginData)
          })
          .then((response)=> response.json())
          
          .then((data) => {
             const id = data.data[0]._id
             const token =data.token
             const profileImage = data.data[0].profileImage
             const userName = data.data[0].userName
              console.log('Response:', data);
              console.log('Success:', data.message);
              localStorage.setItem('ID',id)
              localStorage.setItem('token',token)
              if(data.message=="password mismatch"){
                  navToSamePage()
         
              }
              else if(data.message=="please register here...!"){
                  navToRegister()
                 
              }
              else{
                  navToHome()
                  
              }
            })
          .catch((error) => {
            Swal.fire(error.message,"","error")
          console.log('Error:', error);
          
          });
      }

      function fbReg(e){
         e.preventDefault()
         {<FacebookLogin/>}
      }

  return (
    <div>
         <section class="user-form-part">
         <div class="user-form-banner">
            <div class="user-form-content">
               <a href="#"><img src={firecolourWhite} alt="logo"/></a>
               <h1>Advertise your assets <span>Buy what are you needs.</span></h1>
               <p>Biggest Online Advertising Marketplace in the World.</p>
            </div>
         </div>
         <div class="user-form-category">
            <div class="user-form-header"><a href="#"><img src={firecolourBlack} alt="logo" width={20} height={40}/></a><a href="/"><i class="fas fa-arrow-left"></i></a></div>
            <div class="user-form-category-btn">
               <ul class="nav nav-tabs">
                  <li><Link to="/loginForm" class="nav-link active" 
                  data-toggle="tab"
                  >sign in</Link></li>
                  <li><Link to="/registerForm" class="nav-link" 
                  data-toggle="tab"
                  >sign up</Link></li>
               </ul>
            </div>
            
            <div class="tab-pane active" id="login-tab">
               <div class="user-form-title">
                  <h2>Welcome!</h2>
                  <p>Use credentials to access your account.</p>
               </div>
               <ul class="user-form-option">
                  <li><button onClick={(e)=>{fbReg(e)}}><i class=""></i><span><FacebookLogin/></span></button></li>
                  {/* <li><a href="#"><i class="fab fa-twitter"></i><span>twitter</span></a></li> */}
                  <li><button type="button" ><GoogleLogin /></button></li>
               </ul>
               <form>
                  <div class="row">
                     <div class="col-12">
                        <div class="form-group"><input onChange={(e)=>setPhoneNumber(e.target.value)} type="tel" class="form-control" placeholder="Phone number"/><small class="form-alert">Please follow this example - 01XXXXXXXXX</small></div>
                     </div>
                     <div class="col-12">
                        <div class="form-group"><input onChange={(e)=>setPassword(e.target.value)} type="password" class="form-control" id="pass" placeholder="Password"/><button type="button" class="form-icon"><i class="eye fas fa-eye"></i></button><small class="form-alert">Password must be 6 characters</small></div>
                     </div>
                     <div class="col-6">
                        {/* <div class="form-group">
                           <div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" id="signin-check"/><label class="custom-control-label" for="signin-check">Remember me</label></div>
                        </div> */}
                     </div>
                     <div class="col-6">
                        <div class="form-group text-right"><a href="/forgetPassword" class="form-forgot">Forgot password?</a></div>
                     </div>
                     <div class="col-12">
                        <div class="form-group"><button onClick={(e)=>UserLogin(e)} type="button" class="btn btn-inline"><i class="fas fa-unlock"></i><span>Enter your account</span></button></div>
                     </div>
                  </div>
               </form>
               <div class="user-form-direction">
                  <p>Don't have an account? click on the <a href='registerForm'>( sign up )</a>button above.</p>
               </div>
            </div>
         </div>
      </section>
      <Helmet>
    <script type="media_type" src="../js/vendor/jquery-1.12.4.min.js"></script>
    <script type="media_type" src="../js/vendor/popper.min.js"></script>
    <script type="media_type" src="../js/vendor/bootstrap.min.js"></script>
    <script type="media_type"src="../js/custom/main.js"></script>
    <script type="media_type"src="../js/vendor/slick.min.js"></script>
    <script type="media_type"src="../js/custom/slick.js"></script>
    </Helmet>
    </div>
  )
}
