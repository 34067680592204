import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import noProfileAvt from "../images/avatar/No_image_available.svg.webp"
import noProductImg from '../images/product/no-image.jpg'

export default function ViewSingleAdDetails() {
   const token = localStorage.getItem('token')
   const [quotes, setquotes] = useState('');
   const [description, setdescription] = useState('')
   const x = useLocation()
   const [SingleData, setSingleData] = useState('');
   const Token = localStorage.getItem('token')
   console.log(x);
   const nav = useNavigate()
   

   async function singleproduct() {
      await axios({
        'method': 'GET',
        'url':`https://node.firehrm.com/FK/owner/getById-product/`+x?.state,
        'headers': {
       // 'Authorization':token,
        'Content-Type': 'application/json',
        },
      }).then((data) => {
         console.log("single product",data?.data?.data?.[0])
       setSingleData(data?.data?.data?.[0])
      }).catch((error)=>{
        console.log(error);
      })
      };

      useEffect(() => {
         singleproduct();
      }, []);

      function Review(e){

         e.preventDefault()
         fetch('https://node.firehrm.com/FK/user/postReview/'+x?.state,{
             method:'POST',
             headers:{
                  'Authorization':token,
                 'Content-Type': 'application/json'
               },
             body: JSON.stringify({quotes,description})
             })
             .then((response)=> response.json())
             
             .then((data) => {
                 console.log('Success:', data)
               //   setplanAmount(data?.paymentDetailsUpdate?.subscriptionAmount)
               //   setplanID(data?.paymentDetailsUpdate?._id)
               //   setorderID(data?.orderId)
               //   if (data?.success == 'true') {
               //     RazorPayIntegration(e);
               // }
               nav('/')
             })
             .catch((error) => {
             console.log('Error:', error);
             });
         }
   

      const state = {
         responsive: {
             0: {
                 items: 1,
             },
             450: {
                 items: 2,
             },
             600: {
                 items: 3,
             },
             1000: {
                 items: 4,
             },
         },
   
     }
   

  return (
    <div>
        <section class="inner-sec ad-details-part">
         <div class="container">
            <div class="row">
               <div class="col-lg-10 m-auto">
                  <div class="row">
                     <div class="col-md-6 col-lg-6">
                        <div class="common-card price">
                           <h3>${SingleData?.productDetails?.productPrice}<span>/{SingleData?.productDetails?.priceCondition}</span></h3>
                           <i class="fas fa-tag"></i>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-6">
                        <button type="button" class="common-card number" data-toggle="modal" data-target="#number">
                           <h3>(+91){SingleData?.productOwnerDetails?.phoneNumber}<span>Contact Number</span></h3>
                           <i class="fas fa-phone"></i>
                        </button>
                     </div>
                  </div>
                  <div class="common-card">
                     <ol class="breadcrumb ad-details-breadcrumb">
                        <li>
                              {SingleData?.productDetails?.adType == 'sale'?<span class="flat-badge sale">sale</span>:''}
                              {SingleData?.productDetails?.adType == 'rent'?<span class="flat-badge rent">rent</span>:''}
                              {SingleData?.productDetails?.adType == 'lease'?<span class="flat-badge booking">Lease</span>:''}
                           
                        </li>
                        <li class="breadcrumb-item"><a href="#">{SingleData?.productDetails?.productCategory}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{SingleData?.productDetails?.productSubCategory}</li>
                     </ol>
                     <h5 class="ad-details-address">{SingleData?.productOwnerDetails?.address}, {SingleData?.productOwnerDetails?.city},{SingleData?.productOwnerDetails?.state}</h5>
                     <h3 class="ad-details-title">{SingleData?.productDetails?.productTitle}</h3>
                     <div class="ad-details-meta"><a class="view"><i class="fas fa-eye"></i><span><strong>(134)</strong>preview</span></a><a class="click"><i class="fas fa-mouse"></i><span><strong>(76)</strong>click</span></a><a href="#review" class="rating"><i class="fas fa-star"></i><span><strong>(29)</strong>review</span></a></div>
                     <div class="ad-details-slider-group">
                     {SingleData?.productDetails?.productImage ?
                     <OwlCarousel items={5}
                     className="owl-theme"
                     loop
                     nav
                     margin={10}
                     autoplay={true}
                     dots={false}
                     responsive={state.responsive}
                     animateOut={'fadeOut'}
                     animateIn={'flipInX'}>
                     {SingleData?.productDetails?.productImage.map((x=>
                        <div class="ad-details-slider slider-arrow">
                           <div><img src={x} alt="details" width={300} height={300}/></div>
                           {/* <div><img src="images/product/01.jpg" alt="details"/></div>
                           <div><img src="images/product/01.jpg" alt="details"/></div>
                           <div><img src="images/product/01.jpg" alt="details"/></div> */}
                        </div>
                     )) }</OwlCarousel>
                       :
                       <div class="ad-details-slider slider-arrow">
                           <div><img src={noProductImg} alt="details" width={300} height={300}/></div>
                        </div>
                       }
                        <div class="cross-vertical-badge ad-details-badge"><i class="fas fa-clipboard-check"></i><span>recommend</span></div>
                     </div>
                     {/* <div class="ad-thumb-slider">
                        <div><img src="images/product/01.jpg" alt="details"/></div>
                        <div><img src="images/product/01.jpg" alt="details"/></div>
                        <div><img src="images/product/01.jpg" alt="details"/></div>
                        <div><img src="images/product/01.jpg" alt="details"/></div>
                     </div> */}
                     {/* <div class="ad-details-action"><button type="button" class="wish"><i class="fas fa-heart"></i>bookmark</button><button type="button"><i class="fas fa-exclamation-triangle"></i>report</button><button type="button" data-toggle="modal" data-target="#ad-share"><i class="fas fa-share-alt"></i>share </button></div> */}
                  </div>
                  <div class="common-card">
                     <div class="card-header">
                        <h5 class="card-title">Specification</h5>
                     </div>
                     <ul class="ad-details-specific">
                        <li>
                           <h6>price:</h6>
                           <p>${SingleData?.productDetails?.productPrice}</p>
                        </li>
                        <li>
                           <h6>seller type:</h6>
                           <p>{SingleData?.productOwnerDetails?.accountType}</p>
                        </li>
                        <li>
                           <h6>published:</h6>
                           <p>{SingleData?.createdAt}</p>
                        </li>
                        <li>
                           <h6>location:</h6>
                           <p>{SingleData?.productDetails?.productLocation}</p>
                        </li>
                        <li>
                           <h6>category:</h6>
                           <p>{SingleData?.productDetails?.productCategory}</p>
                        </li>
                        <li>
                           <h6>condition:</h6>
                           <p>{SingleData?.productDetails?.productCondition}</p>
                        </li>
                        <li>
                           <h6>price type:</h6>
                           <p>{SingleData?.productDetails?.priceCondition}</p>
                        </li>
                        <li>
                           <h6>ad type:</h6>
                           <p>{SingleData?.productDetails?.adType}</p>
                        </li>
                     </ul>
                  </div>
                  <div class="common-card">
                     <div class="card-header">
                        <h5 class="card-title">description</h5>
                     </div>
                     <p class="ad-details-desc">{SingleData?.productDetails?.adDescription}</p>
                  </div>
                  {/* <div class="common-card">
                     <div class="card-header">
                        <h5 class="card-title">area map</h5>
                     </div>
                     <iframe class="ad-details-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.3406974350205!2d90.48469931445422!3d23.663771197998262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b0d5983f048d%3A0x754f30c82bcad3cd!2sJalkuri%20Bus%20Stop!5e0!3m2!1sen!2sbd!4v1610539261654!5m2!1sen!2sbd"></iframe>
                  </div> */}
                  <div class="common-card">
                     <div class="card-header">
                        <h5 class="card-title">author info</h5>
                     </div>
                     <div class="ad-details-author">
                        <a class="author-img active">{SingleData?.productOwnerDetails?.profileImage ? <img src={SingleData?.productOwnerDetails?.profileImage}alt="avatar"/>:<img src={noProfileAvt}alt="avatar"/>}</a>
                        <div class="author-meta">
                           <h4><a href="#">{SingleData?.productOwnerDetails?.userName}</a></h4>
                           <h5>joined: february 02, 2021</h5>
                           <p>Corporis dolore libero temporibus minus tempora quia voluptas nesciunt.</p>
                        </div>
                        <div class="author-widget"><a href="profile.html" title="Profile" class="fas fa-eye"></a><a href="message.html" title="Message" class="fas fa-envelope"></a><button type="button" title="Follow" class="follow fas fa-heart"></button><button type="button" title="Number" class="fas fa-phone" data-toggle="modal" data-target="#number"></button><button type="button" title="Share" class="fas fa-share-alt" data-toggle="modal" data-target="#profile-share"></button></div>
                        <ul class="author-list">
                           <li>
                              <h6>total ads</h6>
                              <p>134</p>
                           </li>
                           <li>
                              <h6>total ratings</h6>
                              <p>78</p>
                           </li>
                           <li>
                              <h6>total follower</h6>
                              <p>56</p>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-md-6 col-lg-6">
                        <div class="common-card">
                           <div class="card-header">
                              <h5 class="card-title">opening hour</h5>
                           </div>
                           <ul class="ad-details-opening">
                              <li>
                                 <h6>Saturday</h6>
                                 <p>09:00am - 05:00pm</p>
                              </li>
                              <li>
                                 <h6>Sunday</h6>
                                 <p>09:00am - 05:00pm</p>
                              </li>
                              <li>
                                 <h6>monday</h6>
                                 <p>09:00am - 05:00pm</p>
                              </li>
                              <li>
                                 <h6>tuesday</h6>
                                 <p>09:00am - 05:00pm</p>
                              </li>
                              <li>
                                 <h6>wednesday</h6>
                                 <p>09:00am - 05:00pm</p>
                              </li>
                              <li>
                                 <h6>thursday</h6>
                                 <p>09:00am - 05:00pm</p>
                              </li>
                              <li>
                                 <h6>friday</h6>
                                 <p>closed</p>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-6">
                        <div class="common-card">
                           <div class="card-header">
                              <h5 class="card-title">safety tips</h5>
                           </div>
                           <div class="ad-details-safety">
                              <p>Check the item before you buy</p>
                              <p>Pay only after collecting item</p>
                              <p>Beware of unrealistic offers</p>
                              <p>Meet seller at a safe location</p>
                              <p>Do not make an abrupt decision</p>
                              <p>Never share your financial info</p>
                              <p>Always inspect items before paying</p>
                              <p>Avoid to wire money onlin</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="common-card" id="review">
                     <div class="card-header">
                        <h5 class="card-title">Commends ({SingleData?.review?.length})</h5>
                     </div>
                     <div class="ad-details-review">
                        <ul class="review-list">
                        
                           <li class="review-item">
                           {SingleData?.review && SingleData?.review.map((z=>
                              <div class="review-user">
                                 <div class="review-head">
                                    
                                    <div class="review-profile">
                                       <a href="#" class="review-avatar">{z?.image?<img src={z?.image} alt="review"/>:<img src={noProfileAvt} alt="review"/>}</a>
                                       <div class="review-meta">
                                          <h6><a href="#">{z?.name} -</a><span>{z?.createdAt}</span></h6>
                                          <ul>
                                             {/* <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li> */}
                                             <li>
                                                <h5>- {z?.quotes}</h5>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                   
                                 </div>
                                 <p class="review-desc">{z?.description}</p>
                              </div>
                              ))}
                           </li>
                           {/* <li class="review-item">
                              <div class="review-user">
                                 <div class="review-head">
                                    <div class="review-profile">
                                       <a href="#" class="review-avatar"><img src="images/avatar/02.jpg" alt="review"/></a>
                                       <div class="review-meta">
                                          <h6><a href="#">labonno khan -</a><span>June 02, 2020</span></h6>
                                          <ul>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star active"></i></li>
                                             <li><i class="fas fa-star"></i></li>
                                             <li>
                                                <h5>- for product quality</h5>
                                             </li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                                 <p class="review-desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit Non quibusdam harum ipsum dolor cumque quas magni voluptatibus cupiditate minima quis.</p>
                              </div>
                              <div class="review-author">
                                 <div class="review-head">
                                    <div class="review-profile">
                                       <a href="#" class="review-avatar"><img src="images/avatar/04.jpg" alt="review"/></a>
                                       <div class="review-meta">
                                          <h6><a href="#">john Mahmud</a></h6>
                                          <h6>Author - <span>June 02, 2020</span></h6>
                                       </div>
                                    </div>
                                 </div>
                                 <p class="review-desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit Non quibusdam harum ipsum dolor cumque quas magni voluptatibus cupiditate minima.</p>
                              </div>
                           </li> */}
                        </ul>
                        <form class="review-form">
                           <div class="star-rating"><h5>Give Your commends</h5></div>
                           <div class="review-form-grid">
                              <div class="form-group"><input onChange={(e)=>setquotes(e.target.value)} type="text" class="form-control" placeholder="Subject"/></div>
                              {/* <div class="form-group"><input type="email" class="form-control" placeholder="Email"/></div> */}
                              <div class="form-group">
                              {/* <div class="form-group"><input onChange={(e)=>setquotes(e.target.value)} class="form-control" placeholder="Describe"></input></div> */}
                              </div>
                           </div>
                           <div class="form-group"><textarea onChange={(e)=>setdescription(e.target.value)} class="form-control" placeholder="Describe"></textarea></div>
                           {token ? <button onClick={(e)=>Review(e)} type="submit" class="btn btn-inline review-submit"><i class="fas fa-tint"></i><span>drop your commend</span></button> :
                           <a href='/loginForm' type="submit" class="btn btn-inline review-submit"><i class="fas fa-tint"></i><span>drop your commend</span></a>
                           }</form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </div>
  )
}
