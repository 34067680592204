import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'

export default function ChoosePlan() {
   const productId = localStorage.getItem('productId')
   const ID = localStorage.getItem('ID')
   const token =localStorage.getItem('token')
   const nav = useNavigate()
   const [SubscriptionPlan, setSubscriptionPlan] = useState('');
   const [subscriptionAmount, setplanAmount] = useState('');
   const [subscribeId, setplanID] = useState('');
   const [profileDetails, setProfileDetails] = useState('')
   const [OrderID, setorderID] = useState('')
  async function getSubscriptionPlan() {
    await axios({
      'method': 'GET',
      'url': `https://node.firehrm.com/FK/admin/getAll-package`,
      'headers': {
     'Authorization':token,
      'Content-Type': 'application/json',
      },
    }).then((data) => {
      console.log(data)
      setSubscriptionPlan(data.data.data)
    }).catch((error)=>{
      console.log(error);
    })
    };
  
    useEffect(()=>{getSubscriptionPlan()},[])

    useEffect(() => {
      // GET request using fetch inside useEffect React hook
      fetch('https://node.firehrm.com/FK/user/getById/'+ID)
          .then(response => response.json())
          .then(resp => {console.log('res:',resp.data[0])
          setProfileDetails(resp.data && resp.data[0])
          console.log(profileDetails)
         })                  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
    
      function AdPlan(e,x){
        e.preventDefault(e)
         const subscriptionPlanName = x?.subscriptionPlanName
         const subscriptionPlanDays = x?.subscriptionPlanDays
         const subscriptionAmount = x?.subscriptionAmount
        
         fetch('https://node.firehrm.com/FK/owner/subscripitionPlan',{
             method:'POST',
             headers:{
               //   'Authorization':token,
                 'Content-Type': 'application/json'
               },
             body: JSON.stringify({subscriptionPlanName,productId,subscriptionPlanDays,subscriptionAmount})
             })
             .then((response)=> response.json())
             
             .then((data) => {
                 console.log('Success:', data)
                 setplanAmount(data?.paymentDetailsUpdate?.subscriptionAmount)
                 setplanID(data?.paymentDetailsUpdate?._id)
                 if (subscriptionAmount == 0){
                   nav('/myAds')
                 }
             })
             .catch((error) => {
             console.log('Error:', error);
             });
         }

         function MakePayment(e){

          e.preventDefault()
          fetch('https://node.firehrm.com/FK/owner/createpayment',{
              method:'POST',
              headers:{
                   'Authorization':token,
                  'Content-Type': 'application/json'
                },
              body: JSON.stringify({subscribeId,subscriptionAmount})
              })
              .then((response)=> response.json())
              
              .then((data) => {
                  console.log('Success:', data)
                  setplanAmount(data?.paymentDetailsUpdate?.subscriptionAmount)
                  setplanID(data?.paymentDetailsUpdate?._id)
                  setorderID(data?.orderId)
                  if (data?.success == 'true') {
                    RazorPayIntegration(e);
                }
              })
              .catch((error) => {
              console.log('Error:', error);
              });
          }

        // rayzerPay

        var options = {
          "key": "rzp_test_vcN8VSePA0MiTt", // Enter the Key ID generated from the Dashboard
          "amount": subscriptionAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          "name": "FireBee",
          "description": "Leading Tech company",
          "image": {logo},
          "order_id": OrderID, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "handler": function (response) {
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);
              nav('/myAds')
          }, "prefill": {
              "name": profileDetails?.userName,
              "email": profileDetails?.email,
              "contact": profileDetails?.phoneNumber
          },
          "notes": {
              "address": profileDetails?.address
          },
          "theme": {
              "color": "#3399cc"
          }
      };
      // var rzp1 = new Razorpay(options);
      let rzp1 = new window.Razorpay(options);
      rzp1.on('payment.success', function (response) {
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
          nav('/myAds')
          localStorage.removeItem('productId')
          localStorage.removeItem('rzp_device_id')
      });
      
      async function RazorPayIntegration(e) {
          rzp1.open();
          e.preventDefault();
          // nav('/confirm_delivery')
      
      }
      // async function SubmitDetails(e) {
      //     e.preventDefault();
      //     const token = localStorage.getItem('token');
      //     // debugger
      //     let payload = { name, email, contact, address, amount }
      //     let result = await fetch('http://192.168.1.104:8613/flame/payment/createOrderId',
      //         {
      //             method: "POST",
      //             headers: {
      //                 'Authorization': token,
      //                 'Content-Type': 'application/json'
      //             },
      //             body: JSON.stringify(payload)
      //         })
      //     const res = await result.json()
      //     console.log(res.message)
      //     setData(res.message)
      //     console.log(data, 'data')
      //     if (result.status == 200) {
      //         RazorPayIntegration(e);
      //     }
      // }
      
      useEffect(() => {
          const script = document.createElement('script');
          script.src = 'https://checkout.razorpay.com/v1/Booking_delivery_2.js';
          script.async = true;
          document.body.appendChild(script);
          // console.log(state, 'state')
          // getDetails();
      }, []);

  return (
    <div>
        <section class="adpost-part">
         <div class="container">
         <div class="adpost-card">
                        <div class="adpost-title">
                           <h3>Choose Plan</h3>
                        </div>
                        {SubscriptionPlan && SubscriptionPlan.map(x=>
                        <ul class="adpost-plan-list">
                           
                           <li>
                              <div class="adpost-plan-content">
                                 <h6>{x?.subscriptionPlanName}<span>{'  -Validity '+x?.subscriptionPlanDays}</span></h6>
                                 <p>{x?.description}</p>
                              </div>
                              <div class="adpost-plan-meta">
                                 <h3>{x?.subscriptionAmount}</h3>
                                 <button onClick={(e)=>AdPlan(e,x)} class="btn btn-outline"><span>Select</span></button>
                              </div>
                           </li>
                        </ul>
                        )}
                     </div>
                    {subscribeId ==''?'':<div class="form-group text-right"><button onClick={(e)=>MakePayment(e)} class="btn btn-inline"><i class="fas fa-check-circle"></i><span>Make Payment</span></button></div>}
                     </div>
      </section>
    </div>
  )
}

